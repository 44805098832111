import Tooltip from "antd/es/tooltip";
import { AssetRowMeta } from "../../../../../features/entities/assets-entities";
import { getStringDate } from "../../../../../features/helpers/get-formatted-date";
import useLocale from "../../../../../features/providers/locale-provider";
import { useTheme } from "../../../../../plugins/styled";
import Typography from "../../../../common/typography";

const AssetMetaContent = ({
  name,
  size,
  version,
  description,
  created_at,
}: AssetRowMeta) => {
  const theme = useTheme();
  const { selectedLocale } = useLocale();

  return (
    <>
      <Tooltip title={name}>
        <Typography elementTheme="subtitle1" lineClamp={2} textAlign="center">
          {name}
        </Typography>
      </Tooltip>
      <Typography
        elementTheme="body2"
        color={theme.colors.grey_2}
        textAlign="center"
      >
        {description}
      </Typography>
      <Typography
        elementTheme="overline"
        color={theme.colors.grey_2}
        textAlign="center"
      >
        {size} |{" "}
        {!!version
          ? `V. ${version}`
          : getStringDate(created_at, selectedLocale)}
      </Typography>
    </>
  );
};
export default AssetMetaContent;

