import Container from "@hellodarwin/core/lib/components/common/container";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import FilesList from "@hellodarwin/core/lib/components/files/files-list";
import {
  AssetEntity,
  AssetItemType,
  AssetRecordInformation,
} from "@hellodarwin/core/lib/features/entities/assets-entities";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import { RootState } from "../../app/app-store";
import {
  downloadAsset,
  fetchCompanyFolders,
  selectAllFolders,
  selectFolderById,
  uploadAsset,
} from "../../features/api/slices/assets-slice";
import { selectProfile } from "../../features/api/slices/profile-slice";
import { useNewPartnerApi } from "../../features/api/use-partner-api";

const PartnerFileVaultPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { folderId } = useParams<{ folderId: string }>();
  const folder = useAppSelector((state: RootState) =>
    selectFolderById(state, folderId ?? "")
  );

  const profile = useAppSelector(selectProfile);

  const folders = useAppSelector(selectAllFolders);

  const api = useNewPartnerApi();

  useEffect(() => {
    dispatch(fetchCompanyFolders({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = async (asset: AssetEntity) => {
    try {
      const url = (
        await dispatch(
          downloadAsset({ api, asset_id: asset.asset_id })
        ).unwrap()
      ).link;
      window.open(url, "download");
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAsset = async (
    formData: FormData
  ): Promise<"rejected" | "fulfilled"> =>
    (
      await dispatch(
        uploadAsset({
          api,
          formData,
        })
      )
    ).meta.requestStatus;

  const activeItemType: AssetItemType[] = useMemo(
    () => (!!folder?.record_id ? folder.asset_details : folders),
    [folder, folders]
  );

  const rootRecord: AssetRecordInformation = useMemo(
    () => ({
      record_id: profile.provider_id,
      record_name: profile.name,
      record_type: "provider",
    }),
    [profile]
  );

  const activeRecord: AssetRecordInformation | undefined = useMemo(
    () => (!!folder?.record_id ? folder : undefined),
    [folder]
  );
  return (
    <PageLayout
      app="client"
      title={folder?.record_name ?? t("navigation.fileVault")}
      breadcrumbs={[
        {
          breadcrumbName: t("navigation.home"),
          path: "/",
        },
        {
          breadcrumbName: t("navigation.fileVault"),
        },
      ]}
    >
      <Container>
        <FilesList
          items={activeItemType}
          handleDownload={handleDownload}
          vaultPath={"/files-vault"}
          activeRecord={activeRecord}
          handleSaveAsset={handleSaveAsset}
          rootRecord={rootRecord}
          allowUpload
        />
      </Container>
    </PageLayout>
  );
};

export default PartnerFileVaultPage;

