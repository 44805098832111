import Upload from "antd/es/upload";
import styled from "../../../../plugins/styled";

export const UploadStyled = styled(Upload)`
  display-block;
  
  .ant-upload-select {
    cursor:pointer;
 
    width:100%;
    border:1px dashed ${({ theme }) => theme.colors.grey_3};
    padding:24px;
    border-radius:4px;
    svg {
      margin:auto;
    }
    &:hover {
      border:1px dashed ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.purple_5};

    }
    &> .ant-upload {
      display:flex;
      flex-direction:column;
      gap:24px;
    }
  }

  
`;

export const StepContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
`;
export const StepContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  @media (max-width: ${(props) => props.theme?.breakpoints?.tablet}px) {
    flex-direction: column;
  }
`;

