import Form from "antd/es/form";
import Input from "antd/es/input/Input";
import notification from "antd/es/notification";
import { RcFile } from "antd/es/upload/interface";
import { useEffect, useState } from "react";
import { ProfileEditModalFunctions } from ".";
import { AssetFormValues } from "../../../../features/entities";
import handleDispatchResponse from "../../../../features/helpers/handle-dispatch-response";
import validateURL from "../../../../features/helpers/validate-url";
import { useTranslation } from "../../../../plugins/i18n";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import VideoPlayer from "../../../common/VideoPlayer";
import Button from "../../../common/button";
import Div from "../../../common/div";
import { ModalConfirm } from "../../../common/hd-modal/confirm";
import ModalWithHeaderFooter from "../../../common/hd-modal/layouts/modal-with-header-footer";
import Typography from "../../../common/typography";
import ProfileBanner from "../../../provider/profile/profile-assets/profile-banner";
import ProfileLogo from "../../../provider/profile/profile-assets/profile-logo";
import { VideoWrapper } from "../../../provider/profile/styles";
import Uploader from "../../form-groups/uploader";
import FormItem from "../../form-layouts/form-item";
import FormLabel from "../../form-layouts/form-label";

type ProfileAssetsFormProps = {
  handleCancel: () => void;
  type: "video" | "banner" | "logo";
  asset_url: string;
  asset_title?: string;
  company_id: string;
  setFooter: (footer: JSX.Element) => void;
  functions: ProfileEditModalFunctions;
};

const ProfileAssetsForm = ({
  type,
  asset_url,
  asset_title,
  company_id,
  functions,
  setFooter,
  handleCancel,
}: ProfileAssetsFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;

  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [urlFocus, setUrlFocus] = useState(false);
  const [form] = Form.useForm<AssetFormValues>();

  let formValues: AssetFormValues = {
    title: asset_title || "",
    url: asset_url || "",
  };

  useEffect(() => {
    form.resetFields();
    ["banner", "logo"].includes(type)
      ? !!asset_url
        ? fetch(asset_url)
            .then((res) => res.blob())
            .then((blob) => {
              setFileList([blob as RcFile]);
            })
            .catch((e) => {
              console.error(e);
              setFileList([]);
            })
        : setFileList([])
      : form.setFieldsValue({
          title: asset_title,
          url: asset_url,
        });

    setTimeout(() => {
      setUrlFocus(false);
    }, 300);
  }, [type, asset_title, asset_url]);

  const handleSave = async () => {
    const data = new FormData();

    switch (type) {
      case "video":
        const { title, url } = form.getFieldsValue();
        if (url?.length > 0 && (await !validateURL(url))) {
          notification.error({
            message: t("notification.url_error"),
            placement: "top",
          });
        } else {
          const request = {
            title,
            url,
          };
          data.append("request", JSON.stringify(request));
          !!functions.updateVideos &&
            (await functions
              .updateVideos({ data, companyId: company_id })
              .then((res) =>
                handleDispatchResponse(
                  res,
                  t("notification.saved"),
                  t("notification.saving_error")
                )
              ));
        }
        break;
      case "logo":
        if (!!fileList?.[0]) {
          data.append("logo", fileList[0] as Blob);

          if (
            !fileList?.[0].name.includes(".png") &&
            !fileList?.[0].name.includes(".jpg") &&
            !fileList?.[0].name.includes(".jpeg")
          ) {
            break;
          }

          !!functions.updateLogo &&
            (await functions
              .updateLogo({ data, companyId: company_id })
              .then((res) =>
                handleDispatchResponse(
                  res,
                  t("notification.saved"),
                  t("notification.saving_error")
                )
              ));
        }
        break;
      case "banner":
        if (!!fileList?.[0]) {
          data.append("banner", fileList[0] as Blob);
          !!functions.updateBanner &&
            (await functions
              .updateBanner({ data, companyId: company_id })
              .then((res) =>
                handleDispatchResponse(
                  res,
                  t("notification.saved"),
                  t("notification.saving_error")
                )
              ));
        }
        break;
      default:
        notification.error({
          message: t("notification.saving_error"),
          placement: "top",
        });
        break;
    }
    handleCancel();
  };

  const handleDelete = () => {
    ModalConfirm({
      title: t("deleteModal.title", { type }),
      content: t("deleteModal.content"),
      okText: t("deleteModal.ok"),
      cancelText: t("deleteModal.cancel"),

      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: "24px",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: "24px",
        },
      },
      onOk: async () => {
        switch (type) {
          case "video":
            !!functions.deleteVideos &&
              (await functions
                .deleteVideos({ companyId: company_id })
                .then((res) =>
                  handleDispatchResponse(
                    res,
                    t("notification.saved"),
                    t("notification.saving_error")
                  )
                ));
            break;
          case "banner":
            !!functions.deleteBanner &&
              (await functions
                .deleteBanner({ companyId: company_id })
                .then((res) =>
                  handleDispatchResponse(
                    res,
                    t("notification.saved"),
                    t("notification.saving_error")
                  )
                ));
            break;
          case "logo":
            !!functions.deleteLogo &&
              (await functions
                .deleteLogo({ companyId: company_id })
                .then((res) =>
                  handleDispatchResponse(
                    res,
                    t("notification.saved"),
                    t("notification.saving_error")
                  )
                ));
            break;
        }
        handleCancel();
      },
    });
  };

  const handleUploadChange = (newFileList: RcFile[]) => {
    setFileList(newFileList);
  };

  const removeInputFocus = async () => {
    const { url } = form.getFieldsValue();

    if (!(url?.length > 0 && (await !validateURL(url)))) {
      setTimeout(() => {
        setUrlFocus(false);
      }, 300);
    }
  };

  useEffect(() => {
    setFooter(
      <ModalWithHeaderFooter.Footer
        actions={
          <>
            {!!asset_url ? (
              <Button
                defaultStyle={theme.colors.red_1}
                size="small"
                onClick={handleDelete}
              >
                {t("provider-profile|assets.delete")}
              </Button>
            ) : (
              <Button
                defaultStyle={theme.colors.transparent}
                size="small"
                onClick={handleCancel}
              >
                {t("provider-profile|assets.cancel")}
              </Button>
            )}
            <Button size="small" onClick={handleSave}>
              {t("provider-profile|assets.save")}
            </Button>
          </>
        }
      />
    );
  }, [type, asset_url, asset_title, fileList]);

  const Preview = () => (
    <Div
      flex="column"
      gap={16}
      tablet={{ position: "unset", bordersSide: "bottom" }}
      style={{
        position: "sticky",
        top: 0,
        padding: "15px 20px",
        flex: 2,
      }}
      bordersSide="left"
      borderColor={theme.colors.primary}
      align="center"
    >
      <Typography
        textAlign="center"
        elementTheme="caption"
        style={{ fontWeight: 500 }}
      >
        {t("provider-profile|assets.preview").toUpperCase()}
      </Typography>

      {["logo"].includes(type) ? (
        <ProfileLogo
          logo={
            fileList?.length > 0 && fileList[0]
              ? URL.createObjectURL(fileList[0] as Blob)
              : asset_url
          }
          name={asset_title ?? ""}
        />
      ) : ["banner"].includes(type) ? (
        <ProfileBanner
          banner={
            fileList?.length > 0 && fileList[0]
              ? URL.createObjectURL(fileList[0] as Blob)
              : !!asset_url
                ? `${asset_url}?${new Date().toString()}`
                : ""
          }
          name={asset_title || ""}
        />
      ) : (
        ["video"].includes(type) &&
        !urlFocus && (
          <VideoWrapper>
            <Div className="video">
              {typeof window !== "undefined" && (
                <VideoPlayer
                  url={form.getFieldValue("url")}
                  width="100%"
                  height={"100%"}
                  controls
                  light
                  playing
                />
              )}
            </Div>
            <Typography bold className="video-title">
              {form.getFieldValue("title")}
            </Typography>
          </VideoWrapper>
        )
      )}
    </Div>
  );

  return (
    <>
      <Div flex="row" justify="stretch" tablet={{ flex: "column-reverse" }}>
        <Div
          style={{ padding: "15px 20px", flex: 4 }}
          tablet={{ style: { padding: 0 } }}
          flex="column"
          gap={16}
          fullHeight
        >
          {["video"].includes(type) ? (
            <>
              <Form form={form} layout={"vertical"} initialValues={formValues}>
                <Div flex="column" gap={16}>
                  <FormItem
                    name="title"
                    label={
                      <FormLabel label={t("provider-profile|assets.title")} />
                    }
                  >
                    <Input
                      size={"large"}
                      placeholder={t("provider-profile|assets.title")}
                    />
                  </FormItem>
                  <FormItem
                    name="url"
                    label={
                      <FormLabel label={t("provider-profile|assets.url")} />
                    }
                  >
                    <Input
                      size={"large"}
                      placeholder={t("provider-profile|assets.url")}
                      onFocus={() => setUrlFocus(true)}
                      onBlur={removeInputFocus}
                      onPaste={removeInputFocus}
                    />
                  </FormItem>
                </Div>
              </Form>
            </>
          ) : (
            <Uploader
              fileList={fileList}
              name={type}
              width={type === "banner" ? 1200 : 200}
              height={type === "banner" ? 300 : 200}
              handleChange={handleUploadChange}
            />
          )}
        </Div>
        <Preview />
      </Div>
    </>
  );
};

export default ProfileAssetsForm;

