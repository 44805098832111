export interface ApiError {
  error: string;
  error_code: number;
}

export type ApiStatus = "idle" | "pending";
export interface ApiFunctionsStatus {
  general: ApiStatus;
  [functionName: string]: ApiStatus;
}

export const ApiErrorInitialState: ApiError = {
  error: "",
  error_code: 0,
};

