import { ReactNode, useMemo, useState } from "react";
import { TeamMember } from "../../../../features/entities/core-entities";
import { ProfileModalProps } from "../../../../features/entities/profile-entities";
import { Provider } from "../../../../features/entities/providers-entities";
import ProfileBlocks from "../../../../features/enums/profile-blocks";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import SeeMoreList from "../../../common/see-more/see-more-list";
import ProfileSection from "../profile-section";
import ProfileEmptySection from "../profile-section/empty-state";
import TeamMemberCard from "./team-member-card";
import TeamPreviewModal from "./team-preview-modal";

type ProfileTeamProps = {
  provider: Provider;
  teamMembers: TeamMember[];
  isEditable?: boolean;
  toggleEditModal?: ({ open, type, targetId }: ProfileModalProps) => void;
  id: string;
  setTeamMembers: (values: TeamMember[]) => void;
};

const ProfileTeam = ({
  provider,
  teamMembers,
  isEditable,
  toggleEditModal,
  id,
  setTeamMembers,
}: ProfileTeamProps) => {
  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const viewLimit = isTablet ? 3 : 6;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<TeamMember | undefined>(
    {} as TeamMember
  );
  const handleModal = (teamMember?: TeamMember) => {
    if (isEditable && !!toggleEditModal) {
      toggleEditModal({
        open: true,
        type: ProfileBlocks.Team,
        targetId: teamMember?.provider_team_member_id,
      });
    } else {
      setSelectedMember(teamMember);
      setModalOpen(!modalOpen);
    }
  };

  const content: ReactNode[] = useMemo<ReactNode[]>(() => {
    const children: ReactNode[] = [];

    teamMembers.forEach((member) => {
      children.push(
        <TeamMemberCard
          member={member}
          handleClick={(member: TeamMember) => handleModal(member)}
          teamMembers={teamMembers}
          setTeamMembers={setTeamMembers}
        />
      );
    });
    return children;
  }, [teamMembers, isEditable]);

  return (
    <>
      <ProfileSection
        title={t("profile_team|teamTitle", { companyName: provider.name })}
        isEditable={isEditable}
        handleEdit={handleModal}
        hide={teamMembers.length <= 0}
        id={id}
        emptyState={
          <ProfileEmptySection
            sectionName="Team"
            title={t("profile_team|teamEmptyTitle")}
            subtitle={t("profile_team|teamEmptySubtitle")}
            cta={t("profile_team|teamEmptyCta")}
            handleClick={handleModal}
            isEditable={isEditable}
          />
        }
      >
        <SeeMoreList
          grid={{ sm: 1, md: 1, column: 2, gutter: 24 }}
          limit={viewLimit}
          content={content}
        />
      </ProfileSection>

      {!isEditable && (
        <TeamPreviewModal
          provider={provider}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          teamMembers={teamMembers}
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          setMembers={setTeamMembers}
        />
      )}
    </>
  );
};
export default ProfileTeam;

