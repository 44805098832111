import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import { MenuProps } from "antd/es/menu/menu";
import Modal from "antd/es/modal";
import { TableProps } from "antd/es/table";
import Tooltip from "antd/es/tooltip";
import { ReactNode, useMemo, useState } from "react";
import { Contact, ContactInitialState } from "../../../features/entities";
import getFormattedDate from "../../../features/helpers/get-formatted-date";
import getShortId from "../../../features/helpers/get-short-id";
import handleDispatchResponse from "../../../features/helpers/handle-dispatch-response";
import useMediaQuery from "../../../features/helpers/use-media-query";
import { useTranslation } from "../../../plugins/i18n";
import { useTheme } from "../../../plugins/styled";
import { DefaultTheme } from "../../../theme";
import Avatar from "../../common/avatar";
import Button from "../../common/button";
import Div from "../../common/div";
import Dropdown from "../../common/dropdown";
import Table, { ColumnsType } from "../../common/table";
import Typography from "../../common/typography";
import { ProfileDropdown } from "./styles";
import UserFormModal from "./user-form-modal";

type ProfileUsersListProps = {
  users: Contact[];
  contactId?: string;
  companyId: string;
  handleContactDelete: (contactId: string) => Promise<any>;
  handleContactAdd: (contact: Contact) => Promise<any>;
  handleContactEdit?: (contact: Contact) => Promise<any>;
  handleContactAssign: (contact: Contact) => Promise<any>;
  handleEmailExists: (email: string) => Promise<any>;
  isAdmin?: boolean;
  isAccountManager?: boolean;
  isProvider?: boolean;
  Auth0Buttons?: ({
    contact,
    destination,
    compactView,
  }: {
    contact: Contact;
    destination: string;
    compactView: boolean;
  }) => ReactNode;
  destination?: string;
  ClipboardComponent?: ({
    copy,
    title,
  }: {
    copy: string;
    title: string;
  }) => ReactNode;
  isLoading?: boolean;
};

const ProfileUsersList = ({
  users,
  contactId = "",
  companyId,
  handleContactDelete,
  handleContactAdd,
  handleContactEdit,
  handleContactAssign,
  handleEmailExists,
  isAdmin,
  isAccountManager,
  Auth0Buttons,
  destination,
  ClipboardComponent,
  isLoading,
}: ProfileUsersListProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;
  const [activeContact, setActiveContact] = useState<Contact | null>(null);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleDelete = (contact: Contact) => {
    Modal.confirm({
      title: t("profile|profileForm.users.delete.title", {
        name: contact.name,
      }),
      content: t("profile|profileForm.users.delete.subtitle"),
      okText: t("button.confirm"),
      cancelText: t("button.cancel"),
      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: "24px",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: "24px",
        },
      },
      onOk() {
        if (contact.contact_id)
          handleContactDelete(contact.contact_id).then((res) => {
            handleDispatchResponse(
              res,
              t("notification.saved"),
              t("notification.saving_error")
            );
          });
      },
    });
  };
  const isTablet = useMediaQuery({ maxWidth: 1000 });

  const DropdownLabel = ({
    label,
    Icon,
  }: {
    label: string;
    Icon: (props: IconProps) => ReactNode;
  }) => {
    return (
      <Div flex="row" gap={11}>
        <Icon size={14} />
        <Typography elementTheme="body3">{label}</Typography>
      </Div>
    );
  };

  const Label = ({ v, medium }: { v?: string; medium?: boolean }) => {
    if (!v) return v;
    return (
      <Tooltip title={v}>
        <div>
          <Typography nowrap ellipsis medium={medium}>
            {v}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  const columns = useMemo(() => {
    const columns: ColumnsType<Contact> = [];

    if (isAdmin && !!ClipboardComponent) {
      columns.push({
        width: 100,
        title: "Contact ID",
        dataIndex: "contact_id",
        key: "contact_id",
        render: (contactId) =>
          ClipboardComponent({ copy: contactId, title: getShortId(contactId) }),
      });
    }
    columns.push(
      ...[
        {
          width: 300,
          title: t("profile|profileForm.users.list.username"),
          dataIndex: "name",
          key: "name",
          render: (v: Contact["name"]) => (
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Avatar shape="round" size={50} style={{ flexShrink: 0 }}>
                {v[0]}
              </Avatar>
              <div style={{ flex: 1, overflow: "hidden" }}>
                <Label v={v} medium />
              </div>
            </div>
          ),
          sorter: {
            compare: (a: Contact, b: Contact) => a.name.localeCompare(b.name),
            multiple: 4,
          },
        },
        {
          title: t("profile|profileForm.users.list.email"),
          dataIndex: "email",
          key: "email",
          render: (v: Contact["email"]) => <Label v={v} />,
          sorter: {
            compare: (a: Contact, b: Contact) =>
              (a?.email || "").localeCompare(b?.email || ""),
            multiple: 1,
          },
        },
        {
          title: t("profile|profileForm.users.list.phone"),
          dataIndex: "phone",
          key: "phone",
          render: (v: Contact["phone"]) => <Label v={v} />,
          sorter: {
            compare: (a: Contact, b: Contact) =>
              (a?.phone || "").localeCompare(b?.phone || ""),
            multiple: 1,
          },
        },
      ]
    );

    if (isAdmin || isAccountManager) {
      columns.push({
        title: "Last login",
        key: "last_login_at",

        render: (v) => {
          return v.last_login_at ? (
            <Typography elementTheme="caption" nowrap>
              {getFormattedDate(v.last_login_at)}
            </Typography>
          ) : (
            <Typography elementTheme="caption" nowrap>
              --/--/--
            </Typography>
          );
        },
        sorter: {
          compare: (a: Contact, b: Contact) =>
            (a?.last_login_at || "").localeCompare(b?.last_login_at || ""),
          multiple: 1,
        },
      });
    }
    columns.push({
      title: t("profile|profileForm.users.list.role"),
      dataIndex: "role",
      key: "role",

      render: (_, record) => {
        return <Label v={record.roles ? record.roles : "User"} />;
      },
    });
    columns.push({
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      fixed: "right",
      className: "actions",
      render: (_, record) => {
        const actionMenuItems: MenuProps["items"] = [
          {
            label: (
              <DropdownLabel
                label={t("profile|profileForm.users.actions.edit", {
                  name: record?.name,
                })}
                Icon={Edit}
              />
            ),

            key: 2,
            onClick: () => {
              handleUserEdit(record);
            },
            disabled: !handleContactEdit || (!isAdmin && !isAccountManager),
          },
          {
            label: (
              <DropdownLabel
                label={t("profile|profileForm.users.actions.delete", {
                  name: record?.name,
                })}
                Icon={Delete}
              />
            ),
            key: 1,
            onClick: () => {
              handleDelete(record);
            },
            disabled:
              record.contact_id === contactId ||
              (!isAdmin && !isAccountManager),
          },
        ].filter(Boolean);

        return (
          <Dropdown
            items={actionMenuItems}
            menuStyle={{
              borderRadius: 0,
              padding: 0,
              boxShadow: "none",
            }}
            cta={{ size: 30, color: theme.colors.primary }}
            dropdownRender={(originalNode) => (
              <ProfileDropdown>{originalNode}</ProfileDropdown>
            )}
          />
        );
      },
    });

    if (isAdmin && !!Auth0Buttons && destination) {
      columns.push({
        title: "Auth0 Actions",
        dataIndex: "actions",
        key: "auth_actions",
        render: (_, record) => (
          <Auth0Buttons
            contact={record}
            destination={destination}
            compactView={true}
          />
        ),
      });
    }

    return columns;
  }, [users]);

  const onClick = (c: Contact) => {
    setActiveContact(c);
  };

  const handleUserEdit = (contact?: Contact) => {
    setActiveContact(contact ?? ContactInitialState);
    setEditModalOpen(true);
  };

  const tablePros: TableProps<Contact> = {
    dataSource: users,
    sticky: true,
    columns,
    rowKey: (record) => record.contact_id,
    scroll: { x: isAdmin ? 1800 : 1200 },
    onRow: (record) => {
      return {
        onClick: () => {
          onClick(record);
        },
      };
    },
  };
  return (
    <>
      <Div flex="column" gap={34}>
        <Div flex="row" justify="space-between" align="center">
          <Typography.Title level={2} elementTheme="h6" bold nowrap>
            {t("profile|profileForm.users.title")}
          </Typography.Title>
          <Div flex="row" gap={10} fitContent>
            <Button
              headingIcon={<AddNew width={14} height={14} />}
              size="small"
              style={{ minWidth: 177 }}
              tablet={{ style: { width: 32, minWidth: "auto" } }}
              defaultStyle={theme.colors.primary}
              onClick={() => handleUserEdit()}
              children={
                !isTablet ? t("profile|profileForm.users.addUser") : undefined
              }
            />
          </Div>
        </Div>

        <Div>
          <Table<Contact> {...tablePros} />
        </Div>
      </Div>

      <UserFormModal
        open={editModalOpen}
        companyId={companyId}
        handleClose={() => setEditModalOpen(false)}
        handleEditUser={handleContactEdit}
        handleContactAdd={handleContactAdd}
        handleContactAssign={handleContactAssign}
        handleEmailExists={handleEmailExists}
        contact={activeContact}
        showPhone={destination === "Partner" || isAdmin}
        isLoading={isLoading}
      />
    </>
  );
};

export default ProfileUsersList;

