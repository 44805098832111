import { Col, Row } from "antd/es/grid";
import React, { CSSProperties } from "react";
type GridProps = {
  children: any;
  xl?: number;
  xs?: number;
  xxl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  gutter?: number;
  margin?: CSSProperties["margin"];
  className?: string;
  center?: boolean;
  fitContent?: boolean;
  style?: CSSProperties;
};

const Grid = ({
  children,
  xxl = 1,
  xl = 1,
  lg = 1,
  md = 1,
  sm = 1,
  xs = 1,
  gutter,

  margin,
  className,
  center = false,
  fitContent = false,
  style,
}: GridProps) => {
  return (
    <Row
      style={{ ...style, margin, width: fitContent ? "fit-content" : "100%" }}
      className={className}
      gutter={gutter}
      justify={!!center ? "center" : "start"}
    >
      {Array.isArray(children) ? (
        React.Children.toArray(
          children.map((child, i) => (
            <Col
              xl={24 / xl}
              md={24 / md}
              lg={24 / lg}
              sm={24 / sm}
              xxl={24 / xxl}
              xs={24 / xs}
              span={24}
              style={{ marginBottom: gutter }}
              key={`grid-child-item-${i}`}
            >
              {child}
            </Col>
          ))
        )
      ) : (
        <Col
          xl={24 / xl}
          md={24 / md}
          lg={24 / lg}
          sm={24 / sm}
          xxl={24 / xxl}
          xs={24 / xs}
          span={24}
          style={{ marginBottom: gutter }}
        >
          {children}
        </Col>
      )}
    </Row>
  );
};

export default Grid;

