import { ContainerIcons } from "@hellodarwin/icons/dist/backgrounds/icon-entities";
import { CSSProperties, PropsWithChildren, useEffect } from "react";
import { useAppData } from "../../../../features/providers/app-data-provider";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import { DefaultTheme } from "../../../../theme";
import Div from "../../div";
import AppHeader, { AppHeaderProps } from "../app-layout/app-header";
import PageContainer, { PageContainerProps } from "./PageContainer";
import PageHeading, { PageHeadingProps } from "./PageHeading";

export interface PageLayoutProps
  extends AppHeaderProps,
    PageHeadingProps,
    PropsWithChildren {
  className?: string;

  tabTitle?: string;
  isLoading?: boolean;
  styles?: {
    container?: CSSProperties;
    content?: CSSProperties;
    header?: CSSProperties;
    body?: CSSProperties;
    heading?: PageHeadingProps["styles"];
  };
  noGap?: boolean;
  noPadding?: boolean;
  defaultNavCollapsed?: boolean;
  noFooter?: boolean;
}

const PageLayout = ({
  children,
  className,
  tabTitle,
  isLoading,
  styles,
  noGap,
  noPadding,
  defaultNavCollapsed = false,
  title,
  subtitle,
  actions,
  footnote,
  status,
  handleBack,
  noFooter,
  ...appHeaderProps
}: PageLayoutProps) => {
  const {
    withoutPaddingRight,
    withoutPaddingHorizontal,
    withoutPaddingLeft,
    app,
  } = appHeaderProps;

  const { t } = useTranslations();
  const theme = useTheme() as DefaultTheme;
  const { setNavCollapsed } = useAppData();

  useEffect(() => {
    if (!!defaultNavCollapsed) {
      setNavCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultNavCollapsed]);

  useEffect(() => {
    if (typeof document !== "undefined") {
      if (!!tabTitle) {
        document.title = `${tabTitle} | ${t(`appsNames|${app}`)}`;
      } else if (
        (typeof title === "string" || title instanceof String) &&
        !!title
      ) {
        document.title = `${title} | ${t(`appsNames|${app}`)}`;
      } else {
        document.title = t(`appsNames|${app}`);
      }
    }
  }, [tabTitle]);

  if (isLoading) return <></>;

  const containerProps: PageContainerProps = {
    className,
    cap: app === "admin" ? theme.caps.xl : theme.caps.normal,
    withoutPaddingRight,
    withoutPaddingHorizontal,
    styles: {
      ...styles,
      container: {
        ...styles?.container,
        position: "relative",
        paddingTop: app === "admin" ? 16 : undefined,
      },
    },
    noPadding,
    app,
    noGap,
  };

  const headingProps: PageHeadingProps = {
    title,
    subtitle,
    actions,
    footnote,
    status,
    handleBack,
    withoutPaddingHorizontal,
    withoutPaddingLeft,
    withoutPaddingRight,
    styles: styles?.heading,
  };

  return (
    <>
      {app === "admin" && (
        <AppHeader {...appHeaderProps} style={styles?.header} />
      )}
      <PageContainer
        {...containerProps}
        icons={[
          {
            side: "right",
            name: ContainerIcons.Group1Filled,
            color: theme.colors.beige_1,
            top: 300,
            margin: -300,
            width: 900,
            height: 800,
          },
        ]}
        noFooter={noFooter}
      >
        {app !== "admin" && (
          <AppHeader {...appHeaderProps} style={styles?.header} />
        )}
        {(title || actions) && <PageHeading {...headingProps} />}
        <Div flex="column" style={{ ...styles?.body }} gap={32} fullHeight>
          {children}
        </Div>
      </PageContainer>
    </>
  );
};

export default PageLayout;

