import { CSSProperties, ReactNode } from "react";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import { FormLabelContainer } from "./styles";

export interface FormLabelProps {
  label: ReactNode;
  extra?: ReactNode;
  actions?: ReactNode;
  color?: string;
  onClick?: () => void;
  style?: CSSProperties;
}

const Label = (props: FormLabelProps) => {
  const { label, extra, color, onClick, actions, style } = props;
  const theme = useTheme();
  return (
    <FormLabelContainer
      $withExtra={!!extra}
      onClick={!actions ? onClick : undefined}
      style={{ width: "100%", ...style }}
    >
      <Typography
        elementTheme="body2"
        className="hd-form-label"
        color={color}
        style={{ width: "100%" }}
      >
        {label}
      </Typography>
      {!!extra && (
        <Typography
          elementTheme="body3"
          className="hd-form-extra"
          color={color ?? theme.colors.grey_2}
          style={{ width: "100%" }}
        >
          {extra}
        </Typography>
      )}
    </FormLabelContainer>
  );
};

const FormLabel = (props: FormLabelProps) => {
  const { actions, onClick } = props;

  return !!actions ? (
    <Div
      flex="row"
      gap={8}
      align="center"
      justify="space-between"
      style={{ width: "100%" }}
      onClick={onClick}
    >
      <Label {...props} />
      {actions}
    </Div>
  ) : (
    <Label {...props} />
  );
};
export default FormLabel;

