import notification from "antd/es/notification";
import { useEffect } from "react";
import { ProfileEditModalFunctions } from "..";
import { MappedTags } from "../../../../../features/entities";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import Button from "../../../../common/button";
import ModalWithHeaderFooter from "../../../../common/hd-modal/layouts/modal-with-header-footer";
import ExpertiseForm from "./expertise-form";

type ExpertiseEditionProps = {
  handleCancel: () => void;
  setFooter: (footer: JSX.Element) => void;
  tags: MappedTags;
  functions: ProfileEditModalFunctions;
};

const ExpertiseEdition = ({
  handleCancel,
  setFooter,
  tags,
  functions,
}: ExpertiseEditionProps) => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const handleSave = async () => {
    try {
      const serviceTags = tags.selectedServices;
      const specialtyTags = tags.selectedSpecialties.map((t) => t.tag);

      !!functions.createTags &&
        (await functions.createTags({ serviceTags, specialtyTags }));
      !!functions.fetchProfile && (await functions.fetchProfile());
      !!functions.onboardingMatch && (await functions.onboardingMatch());

      notification.success({ message: "Expertises updated", placement: "top" });

      handleCancel();
    } catch (e: any) {
      notification.error({
        message: "Something went wrong.",
        placement: "top",
      });
      console.error(e);
    }
  };

  useEffect(() => {
    setFooter(
      <ModalWithHeaderFooter.Footer
        actions={
          <>
            <Button
              defaultStyle={theme.colors.white_1}
              size="small"
              onClick={handleCancel}
            >
              {t("button.cancel")}
            </Button>
            <Button size="small" onClick={handleSave}>
              {t("button.save")}
            </Button>
          </>
        }
      />
    );
  }, [tags]);

  return <ExpertiseForm selectedTags={tags} functions={functions} />;
};

export default ExpertiseEdition;

