export interface ReviewNotification {
  reviews_notifications_id: string;
  rfp_id: string;
  created_at: string;
  scheduled_at: string;
  sent_at: string;
  disable_review_notifications_at: string;
}
export enum ReviewStatus {
  Hidden = "hidden",
  Public = "public",
}

export enum ReviewVerified {
  Unverified = "Unverified",
  Verified = "Verified",
  Rejected = "Rejected",
}

export interface Review {
  review_id: string;
  provider_id: string;
  rfp_id?: string;
  description_cons?: string;
  description_pros?: string;
  description_summary?: string;
  rating_budget?: number;
  rating_collaboration?: number;
  rating_quality?: number;
  rating_schedule?: number;
  recommend?: boolean;
  status?: ReviewStatus;
  locale?: string;
  review_source?: string;
  service?: string;
  verified?: string;
  reviewed_at?: string;
  industry?: string;
  contact_id?: string;
}

export interface ReviewRequest {
  review: Review;
  contact_name?: string;
  contact_email?: string;
  contact_title?: string;
  company_name?: string;
  company_industry?: string;
  company_size?: string;
  terms?: string;
}

export interface ReviewResult extends Review {
  contact_name: string;
  company_name: string;
  company_id?: string;
  project_industry: string;
  project_service: string;
  title: string;
  size: string;
  city?: string;
  province?: string;
  average?: number;
  SBUID?: string;
}

