import { ReviewResult } from "../entities/reviews-entities";

export const getAverageAllReviews = (reviews: ReviewResult[]) => {
  let reviewSum = 0;
  if (reviews) {
    reviews.forEach((review) => {
      reviewSum += review.average ?? 0;
    });
    return +(reviewSum / reviews.length).toFixed(1);
  }
};
export default getAverageAllReviews;

