import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import { TeamMember } from "../../../../../features/entities";
import Button from "../../../../common/button";
import Div from "../../../../common/div";

interface orderButtonProps {
  member: TeamMember;
  handleClick?: (member: TeamMember) => void;
  teamMembers?: TeamMember[];
  changesOrder?: (newOrder: any) => void;
}

const orderButtons = ({
  teamMembers,
  member,
  handleClick,
  changesOrder,
}: orderButtonProps) => {
  return (
    <Div flex={"column"} gap={12} fitMinContent>
      {teamMembers?.indexOf(member) !== 0 && (
        <Button
          onClick={() => {
            !!handleClick && handleClick(member);
            !!changesOrder && changesOrder(-1);
          }}
          size="circle"
          fitContent={true}
        >
          <TabArrow />
        </Button>
      )}
      {teamMembers?.indexOf(member) !== (teamMembers?.length ?? 0) - 1 && (
        <Button
          onClick={() => {
            !!handleClick && handleClick(member);
            !!changesOrder && changesOrder(1);
          }}
          size="circle"
          fitContent={true}
        >
          <TabArrow down={true} />
        </Button>
      )}
    </Div>
  );
};

export default orderButtons;

