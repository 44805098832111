import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Image = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.60001 21.6L9.21945 13.8959C9.52803 13.5839 9.89436 13.3364 10.2975 13.1675C10.7007 12.9987 11.1328 12.9117 11.5692 12.9117C12.0056 12.9117 12.4378 12.9987 12.8409 13.1675C13.2441 13.3364 13.6104 13.5839 13.919 13.8959L21.5385 21.6M19.3231 19.36L21.4041 17.2559C21.7126 16.9439 22.079 16.6964 22.4822 16.5275C22.8853 16.3587 23.3175 16.2717 23.7539 16.2717C24.1902 16.2717 24.6224 16.3587 25.0255 16.5275C25.4287 16.6964 25.7951 16.9439 26.1036 17.2559L30.4 21.6M3.81539 27.2H28.1846C28.7722 27.2 29.3357 26.964 29.7511 26.5439C30.1666 26.1238 30.4 25.5541 30.4 24.96V7.03999C30.4 6.4459 30.1666 5.87615 29.7511 5.45607C29.3357 5.03599 28.7722 4.79999 28.1846 4.79999H3.81539C3.22783 4.79999 2.66434 5.03599 2.24888 5.45607C1.83341 5.87615 1.60001 6.4459 1.60001 7.03999V24.96C1.60001 25.5541 1.83341 26.1238 2.24888 26.5439C2.66434 26.964 3.22783 27.2 3.81539 27.2ZM19.3231 10.4H19.3349V10.4119H19.3231V10.4ZM19.8769 10.4C19.8769 10.5485 19.8186 10.6909 19.7147 10.796C19.6108 10.901 19.47 10.96 19.3231 10.96C19.1762 10.96 19.0353 10.901 18.9315 10.796C18.8276 10.6909 18.7692 10.5485 18.7692 10.4C18.7692 10.2515 18.8276 10.109 18.9315 10.004C19.0353 9.89899 19.1762 9.83999 19.3231 9.83999C19.47 9.83999 19.6108 9.89899 19.7147 10.004C19.8186 10.109 19.8769 10.2515 19.8769 10.4Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Image;

