import { useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Profile } from "../../../features/entities";
import { AppType } from "../../../features/entities/core-entities";
import { NavigationLink } from "../../../features/entities/layout-entities";
import { Language } from "../../../features/entities/translations_ns";
import useMediaQuery from "../../../features/helpers/use-media-query";
import { useAppData } from "../../../features/providers/app-data-provider";
import theme from "../../../theme";
import DesktopNavigation from "./desktop-navigation";
import MobileNavigation from "./mobile-navigation";
import { ArrowOutlineStyled, NavSider, TriggerStyled } from "./styles";

interface NavigationProps {
  links: NavigationLink[];
  app: AppType;
  profile?: Profile;
  handleLanguageChange?: (newLanguage: Language) => void;
  userLinks?: NavigationLink[];
}

const NavigationSider = ({
  links,
  app,
  userLinks,
  handleLanguageChange,
  profile,
}: NavigationProps) => {
  const { navCollapsed, handleNavCollapse } = useAppData();
  const { pathname } = useLocation();

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  return (
    <ThemeProvider theme={theme}>
      {isTablet ? (
        <MobileNavigation
          links={links}
          pathname={pathname}
          app={app}
          userLinks={userLinks}
          profile={profile}
        />
      ) : (
        <NavSider
          className={app !== "provider" ? "ant-layout-sider-light" : ""}
          collapsible
          collapsed={navCollapsed}
          onCollapse={handleNavCollapse}
          width={theme.appearance.sidebarWidth}
          collapsedWidth={theme.appearance.sidebarWidthCollapsed}
          style={{ top: 0, position: "sticky", height: "100vh" }}
          theme={app === "provider" ? "dark" : "light"}
          trigger={
            <TriggerStyled>
              <ArrowOutlineStyled
                size={24}
                style={{
                  transform: navCollapsed ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 500ms linear",
                }}
              />
            </TriggerStyled>
          }
        >
          <DesktopNavigation
            links={links}
            app={app}
            handleLanguageChange={handleLanguageChange}
          />
        </NavSider>
      )}
    </ThemeProvider>
  );
};

export default NavigationSider;

