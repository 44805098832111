import { CarouselRef } from "antd/es/carousel";
import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTheme } from "../../../plugins/styled";
import Loading from "../../loading";
import Div from "../div";
import Typography from "../typography";
import { CarouselButton, CarouselContainer, CarouselContent } from "./styles";

export type CarouselButtonSize = "small" | "medium" | "large";
export type CarouselButtonPosition =
  | "bottom-left"
  | "top-right"
  | "bottom-right";

interface CarouselProps {
  title?: string;
  actions?: ReactNode;
  emptyState?: ReactNode;
  isLoading?: boolean;
  children: ReactNode[];
  tileWidth?: number;
  buttonStyle?: string;
  buttonSize?: CarouselButtonSize;
  buttonPosition?: CarouselButtonPosition;
  hideOnMobile?: boolean;
  styles?: {
    container?: CSSProperties;
    header?: CSSProperties;
    content?: CSSProperties;
    actions?: CSSProperties;
    carouselButtons?: CSSProperties;
    carouselSingleButton?: CSSProperties;
  };
}

const Carousel = ({
  children,
  title,
  actions,
  emptyState,
  isLoading,
  tileWidth = 300,
  buttonStyle,
  buttonSize = "medium",
  buttonPosition = "bottom-left",
  hideOnMobile,
  styles,
}: CarouselProps) => {
  const theme = useTheme();
  const carouselRef = useRef<CarouselRef>(null); // use useRef directly for ref
  const containerRef = useRef<HTMLDivElement>(null); // More descriptive naming
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeSlideToShow, setActiveSlideToShow] = useState(0);

  const tileWidthAndPadding = tileWidth + 32;
  const size = children.length;

  const handleNext = () => {
    const newSlide = Math.min(size - activeSlideToShow, currentSlide + 1);
    setCurrentSlide(newSlide);
    carouselRef.current?.goTo(newSlide);
  };

  const handlePrev = () => {
    const newSlide = Math.max(0, currentSlide - 1);
    setCurrentSlide(newSlide);
    carouselRef.current?.goTo(newSlide);
  };

  const handleResize = () => {
    if ((containerRef.current?.clientWidth ?? 0) > tileWidthAndPadding) {
      const slidesPerView =
        (containerRef.current?.clientWidth ?? 0) / tileWidthAndPadding;
      setActiveSlideToShow(slidesPerView); // Ensure integer value
    } else {
      setActiveSlideToShow(1);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tileWidth]);

  useEffect(() => {
    handleResize();
  }, [children]);

  const prevDisabled = useMemo(() => currentSlide === 0, [currentSlide]);
  const nextDisabled = useMemo(
    () => currentSlide >= size - activeSlideToShow,
    [currentSlide, size, activeSlideToShow]
  );

  const Buttons = () => {
    if (!size || isLoading || currentSlide === undefined) return <></>;

    if (currentSlide === 0 && size < activeSlideToShow) return <></>;

    return (
      <Div
        flex="row"
        gap={15}
        tablet={{ style: { display: hideOnMobile ? "none" : "flex" } }}
        fitContent
        style={styles?.carouselButtons}
      >
        <CarouselButton
          size="auto"
          $buttonSize={buttonSize}
          withArrowLeft
          onClick={handlePrev}
          defaultStyle={buttonStyle || theme.colors.transparent}
          disabled={prevDisabled}
          style={styles?.carouselSingleButton}
        />
        <CarouselButton
          size="auto"
          $buttonSize={buttonSize}
          withArrowRight
          onClick={handleNext}
          defaultStyle={buttonStyle || theme.colors.transparent}
          disabled={nextDisabled}
          style={styles?.carouselSingleButton}
        />
      </Div>
    );
  };

  return (
    <CarouselContainer flex="column" gap={26} style={styles?.container}>
      {(!!title || ["top-right"].includes(buttonPosition)) && (
        <Div
          flex="row"
          justify="space-between"
          align="center"
          style={styles?.header}
        >
          {!!title && (
            <Typography.Title level={2} elementTheme="h6">
              {title}
            </Typography.Title>
          )}
          {["top-right"].includes(buttonPosition) && <Buttons />}
        </Div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 34,
          height: "100%",
        }}
        ref={containerRef}
      >
        <Div style={{ width: "100%", height: "100%", ...styles?.content }}>
          {isLoading ? (
            <Loading />
          ) : children.length > 0 ? (
            <CarouselContent
              ref={carouselRef}
              dots={false}
              slide="0"
              slidesToShow={activeSlideToShow}
              infinite={false}
              slidesToScroll={1}
            >
              {React.Children.toArray(
                children.map((child, i) => (
                  <div
                    style={{
                      height: "100%",
                      width:
                        activeSlideToShow === 1 ? "100%" : tileWidthAndPadding,
                      padding: 10,
                    }}
                  >
                    {child}
                  </div>
                ))
              )}
            </CarouselContent>
          ) : (
            <Div
              style={{ height: "100%" }}
              flex="row"
              align="center"
              justify="center"
            >
              {emptyState}
            </Div>
          )}
        </Div>
        {(!!actions ||
          ["bottom-left", "bottom-right"].includes(buttonPosition)) && (
          <div
            style={{
              display: "flex",
              flexDirection:
                buttonPosition === "bottom-right" ? "row-reverse" : "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              {["bottom-left", "bottom-right"].includes(buttonPosition) && (
                <Buttons />
              )}
            </div>
            {!!actions && (
              <Div flex={"column"} gap={8} fitContent style={styles?.actions}>
                {actions}
              </Div>
            )}
          </div>
        )}
      </div>
    </CarouselContainer>
  );
};

export default Carousel;

