import { useBreadcrumbs } from "../../../../features/providers/breadcrumbs-provider";
import BreadcrumbsContent from "./breadcrumbs-content";

const Breadcrumbs = () => {
  const { breadCrumbs } = useBreadcrumbs();

  return <BreadcrumbsContent breadcrumbs={breadCrumbs} />;
};

export default Breadcrumbs;

