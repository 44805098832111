import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import ProjectSidebar, {
  ProjectSidebarField,
} from "@hellodarwin/core/lib/components/project/single/project-content/project-sidebar";
import ProjectSingleTile from "@hellodarwin/core/lib/components/project/single/project-content/project-single-tile";
import { Match } from "@hellodarwin/core/lib/features/entities";
import getShortId from "@hellodarwin/core/lib/features/helpers/get-short-id";
import useMediaQuery from "@hellodarwin/core/lib/features/helpers/use-media-query";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import Employees from "@hellodarwin/icons/dist/icons/Employees";
import Fingerprint from "@hellodarwin/icons/dist/icons/Fingerprint";
import Industry from "@hellodarwin/icons/dist/icons/Industry";
import Location from "@hellodarwin/icons/dist/icons/Location";
import ProjectTags from "@hellodarwin/icons/dist/icons/ProjectTags";
import RaisedHand from "@hellodarwin/icons/dist/icons/RaisedHand";
import Service from "@hellodarwin/icons/dist/icons/Service";
import TeamSize from "@hellodarwin/icons/dist/icons/TeamSize";
import Timeline from "@hellodarwin/icons/dist/icons/Timeline";
import { ReactNode, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import ProjectSpecialties from "./project-banner/project-specialties";

type ProjectDetailsProps = {
  match: Match;
  available?: boolean;
  projectContent: ReactNode;
};

const ProjectDetails = ({
  match,
  available,
  projectContent,
}: ProjectDetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const goToHome = () => {
    navigate("/");
  };

  const [sidebarSections, setSidebarSections] = useState<
    ProjectSidebarField[][]
  >([]);

  useEffect(() => {
    const sections: ProjectSidebarField[][] = [];

    const section1: ProjectSidebarField[] = [];
    const section2: ProjectSidebarField[] = [];
    const section3: ProjectSidebarField[] = [];
    const section4: ProjectSidebarField[] = [];

    if (match.project.industry) {
      section1.push({
        label: t(`project|clientDetails.labels.industry`),
        Icon: Industry,
        value: match.project.industry,
      });
    }
    if (!!match?.project?.client?.size) {
      section1.push({
        label: t(`project|clientDetails.labels.size`),
        Icon: Employees,
        value: match?.project?.client?.size,
      });
    }
    if (!!match?.project?.client?.city) {
      section1.push({
        label: t(`project|clientDetails.labels.city`),
        Icon: Location,
        value: match?.project?.client?.city,
      });
    }

    if (!!match.project.service) {
      section2.push({
        label: t(`project|projectPage.projectCategory`),
        Icon: Service,
        value: match.project.service,
      });
    }
    if (!!match.project.start_when) {
      section2.push({
        label: t(`project|projectPage.startWhen`),
        Icon: Timeline,
        value: t(
          `project|projectStartWhen.${match.project.start_when.toLowerCase()}`
        ),
      });
    }
    if (!!match.rfp_id) {
      section2.push({
        label: t(`project|projectID`),
        Icon: Fingerprint,
        value: getShortId(match.rfp_id),
      });
    }
    if (!!match.raised_hand_count) {
      section3.push({
        label: t(`project|projectPage.raiseHandCount`),
        Icon: RaisedHand,
        value: `${match.raised_hand_count}`,
      });
    }
    if (!!match.shortlist_count) {
      section3.push({
        label: t(`project|projectPage.shortlistCount`),
        Icon: TeamSize,
        value: `${match.shortlist_count}/${match?.project?.provider_max}`,
      });
    }
    if (!!match.project?.specialties) {
      section4.push({
        label: t(`project|projectPage.tags`),
        Icon: ProjectTags,
        value: <ProjectSpecialties specialties={match.project.specialties} />,
      });
    }

    if (!!section1?.length) sections.push(section1);
    if (!!section2?.length) sections.push(section2);
    if (!!section3?.length) sections.push(section3);
    if (!!section4?.length) sections.push(section4);

    setSidebarSections(sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet]);

  return (
    <Div
      flex="row"
      justify={"stretch"}
      tablet={{ flex: "column", justify: "flex-start" }}
      gap={24}
    >
      <Div
        flex="column"
        gap={16}
        fitContent
        style={{
          width: isTablet ? "100%" : 245,
          flexBasis: isTablet ? "auto" : 245,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <ProjectSidebar sections={sidebarSections} />
      </Div>
      <Div flex="column" gap={24}>
        {!available && (
          <ProjectSingleTile style={{ height: "auto" }}>
            <Typography elementTheme="subtitle1">
              {t("project|projectPage.notAvailable")}
            </Typography>
            <Button
              defaultStyle={theme.colors.primary}
              fitContent
              onClick={goToHome}
            >
              {t("project|projectPage.findOpportunities")}
            </Button>
          </ProjectSingleTile>
        )}
        <Div
          backgroundColor={
            !available ? theme.colors.gradient : theme.colors.white_1
          }
        >
          {projectContent}
        </Div>
      </Div>
    </Div>
  );
};

export default ProjectDetails;

