import { useTranslations } from "../../../features/providers/translations-provider";
import Modal, { ModalProps } from "../../common/hd-modal";
import Typography from "../../common/typography";
import AssetUploadForm, { AssetUploadFormProps } from "./asset-upload-form";

export interface AssetUploadModalProps
  extends AssetUploadFormProps,
    Omit<ModalProps, "children"> {}

const AssetUploadModal = ({
  handleSaveAsset,
  records,
  owners,
  asset,
  ...modalProps
}: AssetUploadModalProps) => {
  const { t } = useTranslations();
  return (
    <Modal
      {...modalProps}
      styles={{ content: { padding: "48px 96px" }, wrapper: { zIndex: 2000 } }}
    >
      <Typography elementTheme="h5" style={{ marginBottom: 24 }}>
        {t("assets|upload_new_file")}
      </Typography>
      <AssetUploadForm
        handleAfterFinish={modalProps.handleCancel}
        handleSaveAsset={handleSaveAsset}
        records={records}
        owners={owners}
        asset={asset}
        withCancel
      />
    </Modal>
  );
};

export default AssetUploadModal;

