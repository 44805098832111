import { User, useAuth0 } from "@auth0/auth0-react";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import ErrorNetwork from "../../components/error/network";
import LoadingScreen from "../../components/loading/screen";
import { AppType } from "../entities";
import formatLang from "../helpers/format-lang";
import useDebounceFunction from "../hooks/use-debounce-function";
import useLocale from "./locale-provider";

interface LoginContextType {
  handleLogout: () => void;
  refreshApp: () => void;
  user?: User;
  accessToken: string;
}

export const LoginContext = createContext<LoginContextType>({
  handleLogout: () => {},
  refreshApp: () => {},
  user: undefined,
  accessToken: "",
});

interface LoginProviderProps extends PropsWithChildren {
  app: AppType;
  domain: string;
}

const LoginProvider = ({ children, app, domain }: LoginProviderProps) => {
  const navigate = useNavigate();
  const { selectedLocale } = useLocale();
  const [accessToken, setAccessToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [actualUser, setActualUser] = useState<User | undefined>(undefined);

  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const customTokenStorageKey = useMemo(() => `auth0_token_${app}`, [app]);

  const isTokenExpired = (token: string) => {
    if (!token) return true;
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      return Date.now() > decodedToken.exp * 1000;
    } catch {
      return true;
    }
  };

  const refreshTokenIfNeeded = async () => {
    try {
      const token = await getAccessTokenSilently();
      localStorage.setItem(customTokenStorageKey, token);
      setAccessToken(token);
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  };

  const handleLoginWithRedirect = async () => {
    localStorage.setItem(
      "redirectUrl",
      window.location.pathname + (window.location.search || "")
    );

    const params = new URLSearchParams(window.location.search);
    const locale = formatLang(params.get("locale") || selectedLocale);
    const email = params.get("email");

    await loginWithRedirect({
      authorizationParams: {
        prompt: "login",
        screen_hint:
          window.location.pathname === "/signup" ? "signup" : undefined,
        login_hint: email || undefined,
        ui_locales: locale,
      },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem(customTokenStorageKey);
    localStorage.removeItem("redirectUrl");

    logout({
      logoutParams: {
        returnTo: `${window.location.protocol}//${window.location.host}`,
      },
    });
  };

  const fetchUserProfile = async (token: string) => {
    try {
      const response = await fetch(`https://${domain}/userinfo`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return await response.json();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handleTokenDebounced = useDebounceFunction(
    async (skipStoredtoken?: boolean) => {
      const storedToken = localStorage.getItem(customTokenStorageKey);
      if (skipStoredtoken || isTokenExpired(storedToken || "")) {
        isAuthenticated ? refreshTokenIfNeeded() : handleLoginWithRedirect();
      } else {
        setAccessToken(storedToken || "");

        if (["/signup"].includes(window.location.pathname)) {
          navigate("/");
        }
      }
    },
    300
  );

  const refreshApp = () => {
    handleTokenDebounced(true);
    navigate(0);
  };

  useEffect(() => {
    if (!isLoading) {
      handleTokenDebounced();
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (accessToken) {
      fetchUserProfile(accessToken).then(setActualUser);
    } else {
      setActualUser(user);
    }
  }, [accessToken, user]);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem(customTokenStorageKey, accessToken);
    }
  }, [accessToken]);

  const isLoginLoading = useMemo(
    () => isLoading || !accessToken || !actualUser,
    [isLoading, accessToken, actualUser]
  );

  if (isLoginLoading) return <LoadingScreen />;

  if (!!errorMessage.length)
    return <ErrorNetwork errorMessage={errorMessage} />;

  return (
    <LoginContext.Provider
      value={{ handleLogout, user: actualUser, accessToken, refreshApp }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
export default LoginProvider;

