import { FormItemProps as AntdFormItemProps } from "antd/es/form/FormItem";
import FormLabel from "../form-label";
import { StyledFormItem } from "./styles";

export interface FormItemProps extends AntdFormItemProps {
  hideContent?: boolean;
}

const FormItem = ({ hideContent, ...antdProps }: FormItemProps) => {
  const label =
    typeof antdProps.label === "string" ? (
      <FormLabel label={antdProps.label} extra={antdProps.extra} />
    ) : (
      antdProps.label
    );
  return (
    <StyledFormItem $hideContent={hideContent} {...antdProps} label={label} />
  );
};

export default FormItem;

