import {
  ApiError,
  ApiErrorInitialState,
  AssetDownloadResponse,
  AssetEntity,
  AssetFolderEntity,
  AssetWithOwnersEntity,
} from "@hellodarwin/core/lib/features/entities";
import {
  EntityState,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/app-store";
import PartnerApi from "../partner-api";
import SliceRequest from "../slice-request";

const assetsAdapter = createEntityAdapter({
  selectId: (model: AssetEntity) => model.asset_id,
});

const foldersAdapter = createEntityAdapter({
  selectId: (model: AssetFolderEntity) => model.record_id,
});

export interface AssetsState {
  status: "idle" | "pending";
  error: ApiError;
  assets: EntityState<AssetEntity, string>;
  folders: EntityState<AssetFolderEntity, string>;
}

const initialState: AssetsState = {
  status: "idle",
  error: ApiErrorInitialState,
  assets: assetsAdapter.getInitialState(),
  folders: foldersAdapter.getInitialState(),
};

export const fetchCompanyAssets = SliceRequest<
  AssetEntity[],
  { api: PartnerApi }
>("fetchAssetsFromOwner", async ({ api }) => {
  const response = await api.get<AssetEntity[]>(`/assets`);
  return response.data;
});

export const fetchCompanyFolders = SliceRequest<
  AssetFolderEntity[],
  { api: PartnerApi }
>("fetchFoldersFromOwner", async ({ api }) => {
  const response = await api.get<AssetFolderEntity[]>(`/assets/folder`);
  return response.data;
});

export const uploadAsset = SliceRequest<
  AssetWithOwnersEntity,
  { api: PartnerApi; formData: FormData }
>("uploadAsset", async ({ api, formData }) => {
  const response = await api.post<AssetEntity>(
    `/assets`,
    formData,
    undefined,
    "multipart/form-data"
  );
  return response.data;
});

export const downloadAsset = SliceRequest<
  AssetDownloadResponse,
  { api: PartnerApi; asset_id: string }
>("downloadAsset", async ({ api, asset_id }) => {
  return (await api.get<AssetDownloadResponse>(`/assets/download/${asset_id}`))
    .data;
});

const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyAssets.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchCompanyAssets.fulfilled, (state, { payload }) => {
      assetsAdapter.upsertMany(state.assets, payload);
      state.status = "idle";
    });
    builder.addCase(fetchCompanyAssets.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status = "idle";
    });
    builder.addCase(uploadAsset.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(uploadAsset.fulfilled, (state, { payload }) => {
      if (!!payload.records) {
        for (const record of payload.records) {
          foldersAdapter.updateOne(state.folders, {
            id: record.record_id,
            changes: {
              asset_details: [
                ...state.folders.entities[record.record_id].asset_details,
                payload,
              ],
            },
          });
        }
      }

      state.status = "idle";
    });
    builder.addCase(uploadAsset.rejected, (state, { payload }) => {
      state.status = "idle";
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(downloadAsset.pending, (state, { payload }) => {
      state.status = "pending";
    });
    builder.addCase(downloadAsset.fulfilled, (state, { payload }) => {
      assetsAdapter.updateOne(state.assets, {
        id: payload.asset.asset_id,
        changes: payload.asset,
      });
      state.status = "idle";
    });
    builder.addCase(downloadAsset.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
    builder.addCase(fetchCompanyFolders.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchCompanyFolders.fulfilled, (state, { payload }) => {
      foldersAdapter.setAll(state.folders, payload);
      state.status = "idle";
    });
    builder.addCase(fetchCompanyFolders.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = "idle";
    });
  },
});

export const { selectAll: selectAllAssets, selectById: selectAssetById } =
  assetsAdapter.getSelectors((state: RootState) => state.assets.assets);

export const { selectAll: selectAllFolders, selectById: selectFolderById } =
  foldersAdapter.getSelectors((state: RootState) => state.assets.folders);

export const isError = (state: RootState) =>
  state.assets.error !== ApiErrorInitialState;

export const assetsReducer = assetsSlice.reducer;

