import { Breadcrumb } from "../../../../features/entities/context-entities";
import BreadcrumbDivider from "./breadcrumb-divider";
import { StyledBreadcrumbs } from "./styles";
import useBreadcrumbRender from "./use-breadcrumb-render";

interface BreadcrumbsContentProps {
  breadcrumbs: Breadcrumb[];
}

const BreadcrumbsContent = ({ breadcrumbs }: BreadcrumbsContentProps) => {
  const breadcrumbRender = useBreadcrumbRender();

  return (
    <div style={{ overflow: "hidden" }}>
      <StyledBreadcrumbs
        style={{ width: "100%" }}
        separator={<BreadcrumbDivider />}
        items={breadcrumbs}
        itemRender={breadcrumbRender}
      />
    </div>
  );
};

export default BreadcrumbsContent;
