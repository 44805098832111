import { IconProps } from "@hellodarwin/icons/dist/features/entities/general";
import { ReactNode } from "react";
import prefixHTTPS from "../../../../features/helpers/prefix-https";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";

const InformationCard = ({
  title,
  value,
  Icon,
  link,
  tooltip,
}: {
  title: string;
  value: string;
  Icon: (props: IconProps) => ReactNode;
  link?: string;
  tooltip?: string;
}) => {
  const theme = useTheme();

  return (
    <Div
      style={{ padding: 16, paddingTop: 19 }}
      borderColor={theme.colors.beige_2}
      backgroundColor={theme.colors.beige_4}
      overflow="hidden"
    >
      <Div flex="column" gap={11} align="center" justify="center">
        <Div flex="column" align="center" gap={4}>
          <Icon size={18} style={{ color: theme.colors.grey_2 }} />

          <Typography
            elementTheme="overline"
            color={theme.colors.grey_2}
            textAlign="center"
            nowrap
            ellipsis
          >
            {title}
          </Typography>
        </Div>

        <Typography
          elementTheme="h6"
          color={theme.colors.primary}
          textAlign="center"
          nowrap
          ellipsis
        >
          {link ? (
            <a
              rel="nofollow"
              href={prefixHTTPS(link.toLowerCase(), "profile_header")}
              target="_blank"
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
            >
              {value}
            </a>
          ) : (
            value
          )}
        </Typography>
      </Div>
    </Div>
  );
};

export default InformationCard;

