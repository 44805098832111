// getPaginationTotal returns pagination count depending on page and amount of items
const getPaginationTotal = (
  page: number,
  size: number,
  len: number
): number => {
  return len === size ? page * size + size : page * size;
};
export default getPaginationTotal;

