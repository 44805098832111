import { useTheme } from "styled-components";
import { SpinContainer } from "./styles";

export type SpinSize = "extra-large" | "large" | "medium" | "small";
interface SpinProps {
  color?: string;
  size?: SpinSize;
}
const Spin = ({ color, size = "medium" }: SpinProps) => {
  const theme = useTheme();
  return (
    <SpinContainer $color={color ?? theme.colors.purple_1} $size={size}>
      <div></div>
      <div></div>
      <div></div>
    </SpinContainer>
  );
};

export default Spin;

