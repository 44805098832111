import styled, { css } from "styled-components";
import { AppType } from "../../../../features/entities";
import { createFontSizeCSS } from "../../../../theme/global-style";
import { SubNavigationMenuItemsListSize } from "./subitems-list";
import { SubitemsListDropdownPosition } from "./subitems-list-dropdown";

interface MenuItemLabelProps {
  $isCurrent?: boolean;
  $isCollapsed?: boolean;
  $isSublink?: boolean;
  $withBorderTop?: boolean;
  $noStyle?: boolean;
}
export interface MenuItemLabelContainerProps extends MenuItemLabelProps {
  $app: AppType;
}

export const MenuItemColorsStyling = css<MenuItemLabelContainerProps>`
  color: ${({ theme, $isCurrent, $app }) =>
    $isCurrent
      ? $app === "provider"
        ? theme.colors.purple_3
        : $app === "admin"
          ? theme.colors.purple_1
          : theme.colors.purple_2
      : $app === "provider"
        ? theme.colors.white_1
        : theme.colors.grey_2};

  ${({ $app, theme, $noStyle }) =>
    !$noStyle &&
    `
  &:hover {
    background-color: ${
      $app === "provider"
        ? theme.colors.purple_2
        : $app === "admin"
          ? theme.colors.purple_3
          : theme.colors.purple_5
    };
    color: ${
      $app === "provider"
        ? theme.colors.white_1
        : $app === "admin"
          ? theme.colors.purple_5
          : theme.colors.purple_1
    };
  }`}
`;

export const MenuItemLabelContainer = styled.div<MenuItemLabelContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: ${({ $isSublink, $noStyle }) => ($noStyle || $isSublink ? "4px 14px" : "11px 16px")};

  width: ${({ $isCollapsed, $isSublink }) =>
    $isCollapsed ? "fit-content" : $isSublink ? "181px" : "218px"};
  cursor: pointer;

  ${MenuItemColorsStyling}

  border-top: ${({ $withBorderTop, theme }) =>
    $withBorderTop ? `1px solid ${theme.colors.primary}` : "none"};
 

  svg {
    flex-shrink: 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    m
  }
`;

export const MenuItemLabelText = styled.div<MenuItemLabelProps>`
  ${({ $isSublink }) => createFontSizeCSS($isSublink ? "body3" : "body2")}
  font-size ${({ $isSublink }) => ($isSublink ? "14px" : "15px")};
  font-weight: ${({ $isSublink }) => ($isSublink ? "400" : "500")};
`;

export const SubitemsDropdownWrapperStyled = styled.div<{
  $isActive?: boolean;
  $position?: SubitemsListDropdownPosition;
}>`
  display: ${({ $isActive }) => ($isActive ? "block" : "none")};
  position: absolute;
  top: ${({ $position }) =>
    $position === "bottom" ? "calc(100% - 15px + 8px)" : "-15px"};
  left: ${({ $position }) =>
    $position === "bottom" ? "-45px" : "calc(100% - 45px)"};
  width: max-content;
  padding: 15px 45px;
  z-index: 2000;
  min-width: calc(100% + 45px + 45px);
`;
export const SubitemsDropdownStyled = styled.div<{
  $app: AppType;
  $size: SubNavigationMenuItemsListSize;
}>`
  padding: 0;
  padding-bottom: ${({ $size }) => ($size === "large" ? 0 : 8)}px;

  width: max-content;
  background-color: ${({ theme, $app }) =>
    $app === "provider" ? theme.colors.primary : theme.colors.white_1};
  border: 1px solid
    ${({ theme, $app }) =>
      $app === "provider" ? theme.colors.grey_4 : theme.colors.primary};
  ${MenuItemLabelContainer} {
    width: 100%;
  }
  .ant-badge {
    display: block;
    width: 100%;
  }
`;

export const NavigationMenuItemStyled = styled.div<{ $isCollapsed?: boolean }>`
  width: ${({ $isCollapsed }) => ($isCollapsed ? "fit-content" : "100%")};
  position: relative;
  z-index: 1200;
  .ReactCollapse--collapse {
    transition: height 300ms;
  }
`;

