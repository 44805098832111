import Checkbox from "antd/es/checkbox";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Input from "antd/es/input/Input";
import TextArea from "antd/es/input/TextArea";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import React, { KeyboardEventHandler, useEffect, useState } from "react";
import { ProfileEditModalFunctions } from "..";
import { createTagAdapter } from "../../../../../features/adapters/tag-adapter";
import {
  MappedTags,
  Portfolio,
  PortfolioAsset,
  PortfolioRequest,
  Provider,
} from "../../../../../features/entities";
import handleDispatchResponse from "../../../../../features/helpers/handle-dispatch-response";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import { DefaultTheme } from "../../../../../theme";
import { Tablet } from "../../../../common/Media";
import Button from "../../../../common/button";
import Div from "../../../../common/div";
import { ModalConfirm } from "../../../../common/hd-modal/confirm";
import ModalWithHeaderFooter from "../../../../common/hd-modal/layouts/modal-with-header-footer";
import Typography from "../../../../common/typography";
import FormItem from "../../../form-layouts/form-item";
import FormLabel from "../../../form-layouts/form-label";
import PortfolioAssets from "./portfolio-assets";

interface PortfolioFormValues {
  portfolio_title: string;
  project_url: string;
  client_company_name: string;
  description: string;
  service: string;
  specialties: string[];
}

type PortfolioFormProps = {
  provider: Provider;
  tags: MappedTags;
  handleCancel: () => void;
  setFooter: (element: JSX.Element) => void;
  activePortfolio?: Portfolio;
  functions: ProfileEditModalFunctions;
};

const PortfolioForm = ({
  provider,
  tags,
  handleCancel,
  setFooter,
  activePortfolio,
  functions,
}: PortfolioFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as DefaultTheme;

  const [form] = useForm<PortfolioFormValues>();

  const [assets, setAssets] = useState<PortfolioAsset[]>([]);
  const [madeWithHelloDarwin, setMadeWithHelloDarwin] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const tagAdapter = createTagAdapter();
  const [initialValues, setInitialValues] = useState<PortfolioFormValues>({
    portfolio_title: "",
    project_url: "",
    client_company_name: "",
    description: "",
    service: "",
    specialties: [],
  });

  const handleResetAndCancel = () => {
    setAssets([]);
    form.resetFields();
    handleCancel();
  };

  useEffect(() => {
    if (!!activePortfolio) {
      const portfolioTags = tagAdapter.selectPortfolioTags(
        activePortfolio?.tags
      );
      setInitialValues({
        portfolio_title: activePortfolio?.portfolio_title,
        project_url: activePortfolio?.project_url,
        client_company_name: activePortfolio?.client_company_name,
        description: activePortfolio?.description,
        service: portfolioTags?.services?.[0],
        specialties: portfolioTags?.specialties,
      });

      setMadeWithHelloDarwin(!!activePortfolio?.from_hellodarwin_at);
      setIsPublic(!!activePortfolio?.public_at);
      setAssets(activePortfolio?.assets);
    } else {
      setInitialValues({
        portfolio_title: "",
        project_url: "",
        client_company_name: "",
        description: "",
        service: "",
        specialties: [],
      });
      setAssets([]);
      setMadeWithHelloDarwin(false);
      setIsPublic(false);
    }
  }, [activePortfolio]);

  const handleSave = () => {
    form
      ?.validateFields()
      .then(async (values) => {
        const tags: string[] = [];
        if (values?.specialties !== undefined)
          tags.push(...values?.specialties);
        tags.push(values?.service);

        const data = new FormData();
        let formPortfolio: PortfolioRequest = {
          provider_id: provider?.provider_id,
          description: values?.description,
          project_url: values?.project_url,
          portfolio_title: values?.portfolio_title,
          client_company_name: values?.client_company_name,
          made_with_hellodarwin: madeWithHelloDarwin,
          public: isPublic,
          tags,
        };

        if (!!activePortfolio?.provider_portfolio_id) {
          formPortfolio["provider_portfolio_id"] =
            activePortfolio?.provider_portfolio_id;
        }

        data.append("request", JSON.stringify(formPortfolio));
        data.append("assets", JSON.stringify(assets));

        assets.forEach((asset) => {
          if (!!asset?.file && !!asset?.filename) {
            data.append(asset?.filename, asset?.file);
          }
        });

        if (!!activePortfolio?.provider_portfolio_id) {
          !!functions.updatePortfolio &&
            (await functions
              .updatePortfolio({ data })
              .then((res) =>
                handleDispatchResponse(
                  res,
                  t("notification.saved"),
                  t("notification.saving_error")
                )
              ));
        } else {
          !!functions.submitPortfolio &&
            (await functions
              .submitPortfolio({ data })
              .then((res) =>
                handleDispatchResponse(
                  res,
                  t("notification.saved"),
                  t("notification.saving_error")
                )
              ));
        }

        handleResetAndCancel();
      })
      .catch((error) =>
        notification.error({
          message: error.errorFields[0].errors[0],
          placement: "top",
        })
      );
  };

  const handleDelete = () => {
    ModalConfirm({
      title: t("profile|assetsModal.confirm"),
      content: t("profile|assetsModal.delete.portfolio"),
      okText: t("profile|assetsModal.confirm"),
      cancelText: t("profile|assetsModal.cancel"),

      okButtonProps: {
        style: {
          backgroundColor: theme.colors.purple_1,
          color: theme.colors.white_1,
          borderRadius: "24px",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: "24px",
        },
      },
      onOk: async () => {
        functions
          .removePortfolio({
            portfolioID: activePortfolio?.provider_portfolio_id,
          })
          .then((res) =>
            handleDispatchResponse(
              res,
              t("notification.saved"),
              t("notification.saving_error")
            )
          );
      },
    });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      (e.currentTarget.nextSibling as HTMLInputElement).focus();
    }
  };

  const Checkboxes = () => (
    <Div
      backgroundColor={theme.colors.white_1}
      borderRadius={12}
      borderColor={theme.colors.grey_4}
      align="flex-start"
      style={{ padding: "0.5rem" }}
      fitMaxContent
      flex="row"
      gap={10}
    >
      <Checkbox
        onChange={() => setMadeWithHelloDarwin(!madeWithHelloDarwin)}
        checked={madeWithHelloDarwin}
      >
        <Typography nowrap elementTheme="caption" medium>
          {t("portfolio|form.label.madeWithHelloDarwin")}
        </Typography>
      </Checkbox>

      <Checkbox onChange={() => setIsPublic(!isPublic)} checked={isPublic}>
        <Typography nowrap elementTheme="caption" medium>
          {t("portfolio|form.label.public")}
        </Typography>
      </Checkbox>
    </Div>
  );

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    setFooter(
      <ModalWithHeaderFooter.Footer
        actions={
          <>
            <Tablet direction="up">
              <Checkboxes />
            </Tablet>
            <Div
              flex="row"
              align="center"
              justify="flex-end"
              gap={16}
              tablet={{ flex: "column-reverse" }}
            >
              {activePortfolio?.provider_portfolio_id ? (
                <Button
                  defaultStyle={theme.colors.red_1}
                  onClick={handleDelete}
                  size="small"
                >
                  {t("button.delete")}
                </Button>
              ) : (
                <Button
                  defaultStyle={theme.colors.white_1}
                  onClick={handleResetAndCancel}
                  size="small"
                >
                  {t("button.cancel")}
                </Button>
              )}
              <Button
                defaultStyle={theme.colors.purple_1}
                onClick={handleSave}
                size="small"
              >
                {t("button.save")}
              </Button>
            </Div>
          </>
        }
      />
    );
  }, [isPublic, madeWithHelloDarwin, activePortfolio, assets]);

  return (
    <Div tablet={{ flex: "column" }} gap={16}>
      <Form
        layout={"vertical"}
        onFinish={handleSave}
        form={form}
        initialValues={initialValues}
      >
        <Div flex="row" gap={32} tablet={{ flex: "column-reverse", gap: 16 }}>
          <Div flex="column" gap={16} style={{ flex: 1 }}>
            <FormItem
              className={"hd-0-mb"}
              name="portfolio_title"
              label={<FormLabel label={t("portfolio|form.label.title")} />}
            >
              <Input
                size={"large"}
                placeholder={t("portfolio|form.placeholder.title")}
                onKeyDown={handleKeyDown}
              />
            </FormItem>
            <FormItem
              className={"hd-0-mb"}
              name="project_url"
              label={<FormLabel label={t("portfolio|form.label.projectURL")} />}
            >
              <Input
                size={"large"}
                placeholder={t("portfolio|form.placeholder.projectURL")}
                onKeyDown={handleKeyDown}
              />
            </FormItem>
            <FormItem
              className={"hd-0-mb"}
              name="client_company_name"
              label={<FormLabel label={t("portfolio|form.label.clientName")} />}
            >
              <Input
                size={"large"}
                placeholder={t("portfolio|form.placeholder.clientName")}
                onKeyDown={handleKeyDown}
              />
            </FormItem>

            <FormItem
              className={"hd-0-mb"}
              name="description"
              label={
                <FormLabel label={t("portfolio|form.label.description")} />
              }
            >
              <TextArea className="text-editor" />
            </FormItem>
          </Div>
          <Div flex="column" gap={16} style={{ flex: 1 }}>
            <PortfolioAssets
              assets={assets}
              setAssets={setAssets}
              portfolio_id={activePortfolio?.provider_portfolio_id || ""}
            />

            <FormItem
              name="service"
              label={<FormLabel label={t("portfolio|form.label.service")} />}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: t("portfolio|form.validation.service"),
                },
              ]}
            >
              <Select
                size={"large"}
                placeholder={t("portfolio|form.placeholder.service")}
                showSearch
                notFoundContent={null}
              >
                {React.Children.toArray(
                  tags?.selectedCategories?.map((category, index) => (
                    <Select.OptGroup
                      key={index}
                      label={t(`tags|${category?.label}`)}
                    >
                      {React.Children.toArray(
                        category?.services?.map((service) => {
                          return (
                            <Select.Option
                              key={service?.tag}
                              value={service?.tag}
                            >
                              {t(`tags|${service?.label}`)}
                            </Select.Option>
                          );
                        })
                      )}
                    </Select.OptGroup>
                  ))
                )}
              </Select>
            </FormItem>
            <FormItem
              name="specialties"
              label={
                <FormLabel
                  label={t("portfolio|form.label.specialties")}
                  extra={t("portfolio|form.disclaimer.specialties")}
                />
              }
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: t("portfolio|form.validation.specialties"),
                },
              ]}
            >
              <Select
                size={"large"}
                placeholder={t("portfolio|form.placeholder.specialties")}
                showSearch
                notFoundContent={null}
                mode={"multiple"}
              >
                {React.Children.toArray(
                  tags?.selectedCategories?.map((category, index) => (
                    <Select.OptGroup
                      key={index}
                      label={t(`tags|${category?.label}`)}
                    >
                      {React.Children.toArray(
                        category?.specialties.map((specialty) => (
                          <Select.Option
                            key={specialty?.tag}
                            value={specialty?.tag}
                          >
                            {t(`tags|${specialty?.label}`)}
                          </Select.Option>
                        ))
                      )}
                    </Select.OptGroup>
                  ))
                )}
              </Select>
            </FormItem>
          </Div>
        </Div>
      </Form>
      <Tablet direction="down">
        <Checkboxes />
      </Tablet>
    </Div>
  );
};

export default PortfolioForm;

