import { CSSProperties, ReactNode, useState } from "react";
import ReactPlayer from "react-player";
import { useTheme } from "../../../plugins/styled";
import { VideoPlayerContainer } from "./styles";

interface VideoPlayerProps {
  url: string;
  title?: ReactNode;
  controls?: boolean;
  light?: boolean;
  playing?: boolean;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  style?: CSSProperties;
}

const VideoPlayer = ({
  url,
  title,
  controls = true,
  light,
  playing = false,
  width = "100%",
  height = "100%",
  style,
}: VideoPlayerProps) => {
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(playing);

  const handlePlay = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
  };

  return (
    <VideoPlayerContainer
      style={{
        background: theme.colors.primary,
        width,
        height,
        ...style,
      }}
    >
      <ReactPlayer
        light={light}
        controls={controls}
        playing={isPlaying}
        onPlay={handlePlay}
        url={url}
        width={width}
        height={height}
        style={{ aspectRatio: 16 / 9 }}
        title={title as string}
      />
    </VideoPlayerContainer>
  );
};
export default VideoPlayer;

