import React, { CSSProperties, useEffect, useState } from "react";
import CollapsePanel, { CollapsePanelProps } from "./CollapsePanel";
import { CollapseContainer } from "./styles";
export type ExpandIconPosition = "start" | "end" | "bottom" | "top" | "center";

export type CollapseActiveKeysType = string[] | string;

export type CollapseProps = {
  accordion?: boolean;
  activeKey?: CollapseActiveKeysType;
  defaultActiveKey?: CollapseActiveKeysType;
  gap?: CSSProperties["gap"];
  ExpandIcon?: CollapsePanelProps["ExpandIcon"];
  expandIconPosition?: ExpandIconPosition;
  expandIconPositionTablet?: ExpandIconPosition;
  className?: string;
  onChange?: (key: CollapseActiveKeysType) => void;
  ghost?: boolean;
  noPadding?: boolean;
  style?: CSSProperties;
  styles?: {
    container?: CSSProperties;
    panelContainer?: CSSProperties;
    panelHeader?: CSSProperties;
    panelContent?: CSSProperties;
  };
  items: CollapsePanelProps[];
};

const Collapse = ({
  accordion,
  activeKey,
  defaultActiveKey = [],
  gap,
  ExpandIcon,
  expandIconPosition,
  expandIconPositionTablet,
  className,
  onChange,
  ghost,
  noPadding,
  styles,
  style,
  items,
}: CollapseProps) => {
  const [activeElements, setActiveElements] =
    useState<CollapseActiveKeysType>(defaultActiveKey);

  const handleClick = (key: string) => {
    const values = !!activeKey ? activeKey : activeElements;

    let newValues: CollapseActiveKeysType;
    if (typeof values === "string" && values === key) {
      newValues = "";
    } else if (!!accordion || typeof values === "string") {
      newValues = key;
    } else if (values?.includes(key)) {
      newValues = values.filter((e) => e !== key);
    } else {
      newValues = [...values, key];
    }

    if (!!onChange) {
      onChange(newValues);
    } else {
      setActiveElements(newValues);
    }
  };

  useEffect(() => {
    if (!!activeKey) {
      setActiveElements(activeKey);
    }
  }, [activeKey]);

  const isActive = (key: string) => {
    const values = !!activeKey ? activeKey : activeElements;
    if (typeof values === "string") {
      return key === values;
    } else {
      return !!values?.includes(key);
    }
  };

  return (
    <CollapseContainer
      style={{
        display: "flex",
        flexDirection: "column",
        gap,
        ...style,
        ...styles?.container,
      }}
      className={className}
    >
      {React.Children.toArray(
        items.map((itm) => (
          <CollapsePanel
            ExpandIcon={ExpandIcon}
            expandIconPosition={expandIconPosition}
            expandIconPositionTablet={expandIconPositionTablet}
            onClick={handleClick}
            isActive={isActive(itm.id)}
            hasGap={!!gap}
            ghost={ghost}
            noPadding={noPadding}
            styles={{
              container: styles?.panelContainer,
              content: styles?.panelContent,
              header: styles?.panelHeader,
            }}
            {...itm}
          />
        ))
      )}
    </CollapseContainer>
  );
};

export default Collapse;

