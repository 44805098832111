import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BreadcrumbButton, BreadcrumbText } from "./styles";

const useBreadcrumbRender = () => {
  const navigate = useNavigate();

  return useMemo(
    () =>
      (
        route: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>,
        params: any,
        routes: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
        paths: string[]
      ) => {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
          <BreadcrumbText>{route.breadcrumbName}</BreadcrumbText>
        ) : route.onClick ? (
          <BreadcrumbButton onClick={route.onClick}>
            {route.breadcrumbName}
          </BreadcrumbButton>
        ) : (
          !!route.path && (
            <BreadcrumbButton onClick={() => navigate(`${route.path}`)}>
              {route.breadcrumbName}
            </BreadcrumbButton>
          )
        );
      },
    [navigate]
  );
};

export default useBreadcrumbRender;
