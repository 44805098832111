import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { Project } from "@hellodarwin/core/lib/features/entities";
import ProjectStatus from "@hellodarwin/core/lib/features/enums/project-status";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import TabArrow from "@hellodarwin/icons/dist/icons/TabArrow";
import ConfigProvider from "antd/es/config-provider";
import Empty from "antd/es/empty";
import List from "antd/es/list";
import Select, { SelectProps } from "antd/es/select";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/app-hooks";
import ProjectSummary from "../../components/referral/project-referral-summary";
import {
  fetchReferredProjects,
  selectReferProjects,
} from "../../features/api/slices/matches-slice";
import { usePartnerApi } from "../../features/api/use-partner-api";
import ReferralProjectCard from "./partner-referral-refer-page/partner-referral-project-card";

const sortByDate = (p1: Project, p2: Project) => {
  return p1.activated_at! > p2.activated_at! ? -1 : 1;
};

const sortByPrice = (p1: Project, p2: Project) => {
  return p1.lead_price! > p2.lead_price! ? -1 : 1;
};

const sortByStatus = (p1: Project, p2: Project) => {
  const map = new Map<ProjectStatus, number>([
    [ProjectStatus.PendingApproval, 1],
    [ProjectStatus.Active, 2],
    [ProjectStatus.EvaluateFinalists, 3],
    [ProjectStatus.FilterCandidates, 4],
    [ProjectStatus.Completed, 5],
    [ProjectStatus.Canceled, 6],
    [ProjectStatus.Rejected, 7],
  ]);
  const value1 = map.get(p1.status);
  const value2 = map.get(p2.status);

  if (!value1 || !value2) {
    return 0;
  }
  if (value1 < value2) {
    return -1;
  }
  if (value1 > value2) {
    return 1;
  }
  return 0;
};

export const filterProjects = (
  projects: Project[],
  sortType: string,
  orderType: string
) => {
  let filtered = projects;
  switch (sortType) {
    case "status":
      filtered = projects.sort(sortByStatus);
      break;
    case "price":
      filtered = projects.sort(sortByPrice);
      break;
    case "recent":
      filtered = projects
        .filter((project) => {
          return project.activated_at;
        })
        .sort(sortByDate);
      break;
  }

  switch (orderType) {
    case "ascending":
      break;
    case "descending":
      filtered = filtered.reverse();
      break;
  }
  return filtered;
};

const VALID_SORT_TYPES = ["status", "price", "recent"];
const VALID_ORDER_TYPES = ["ascending", "descending"];

const ReferralViewPage = () => {
  const { t } = useTranslation();
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const [filteredProjects, setProjects] = useState<Project[]>();
  const referralProjects = useAppSelector(selectReferProjects);
  const [sortType, setSortType] = useState("status");
  const [orderType, setOrderType] = useState("ascending");

  const navigate = useNavigate();
  const location = useLocation();

  const updateUrlParams = (newParams: any) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(newParams).forEach((key) => {
      searchParams.set(key, newParams[key]);
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const getUrlParams = () => {
    return new URLSearchParams(location.search);
  };

  useEffect(() => {
    const urlParams = getUrlParams();
    let urlSortType = urlParams.get("sortType");
    let urlOrderType = urlParams.get("orderType");
    let shouldUpdateUrl = false;

    if (urlSortType && VALID_SORT_TYPES.includes(urlSortType)) {
      setSortType(urlSortType);
    } else {
      setSortType("status");
      urlSortType = "status";
      shouldUpdateUrl = true;
    }

    if (urlOrderType && VALID_ORDER_TYPES.includes(urlOrderType)) {
      setOrderType(urlOrderType);
    } else {
      setOrderType("ascending");
      urlOrderType = "ascending";
      shouldUpdateUrl = true;
    }

    if (shouldUpdateUrl) {
      const searchParams = new URLSearchParams();
      searchParams.set("sortType", urlSortType);
      searchParams.set("orderType", urlOrderType);
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    dispatch(fetchReferredProjects({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProjects(referralProjects);
  }, [referralProjects]);

  useEffect(() => {
    setProjects([...filterProjects(referralProjects, sortType, orderType)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  useEffect(() => {
    let filtered = referralProjects;
    switch (orderType) {
      case "ascending":
        filtered = filtered.reverse();
        break;
      case "descending":
        filtered = filtered.reverse();
        break;
    }
    setProjects([...filtered]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType]);

  const orderOptions: SelectProps["options"] = [
    {
      value: "ascending",
      label: t("project|filters.orderBy.options.ascending"),
    },
    {
      value: "descending",
      label: t("project|filters.orderBy.options.descending"),
    },
  ];

  const OrderBySelect = () => (
    <div>
      <Typography elementTheme="body2" style={{ marginBottom: 8 }}>
        {t("project|filters.orderBy.title")}
      </Typography>
      <Select
        options={orderOptions}
        onChange={(value: string) => {
          setOrderType(value);
          updateUrlParams({ orderType: value });
        }}
        placeholder={t("project|filters.orderBy.title")}
        value={orderType}
        style={{
          width: 221,
        }}
        suffixIcon={
          <TabArrow width={10} height={10} down style={{ marginRight: 10 }} />
        }
      />
    </div>
  );

  const SortOptions: SelectProps["options"] = [
    {
      value: "status",
      label: t("project|filters.sortBy.options.status"),
    },
    {
      value: "price",
      label: t("project|filters.sortBy.options.price"),
    },

    {
      value: "recent",
      label: t("project|filters.sortBy.options.recent"),
    },
  ];

  const SortBySelect = () => {
    return (
      <div>
        <Typography elementTheme="body2" style={{ marginBottom: 8 }}>
          {t("project|filters.sortBy.title")}
        </Typography>
        <Select
          options={SortOptions}
          onChange={(value: string) => {
            setSortType(value);
            updateUrlParams({ sortType: value });
          }}
          placeholder={t("project|filters.sortBy.title")}
          value={sortType}
          style={{ width: 200 }}
          suffixIcon={
            <TabArrow width={10} height={10} down style={{ marginRight: 10 }} />
          }
        />
      </div>
    );
  };

  const renderEmpty = () => <Empty description={t("referral|noReferral")} />;

  return (
    <>
      <PageLayout
        app="provider"
        title={t("referral|myReferral.titleReferral")}
        subtitle={t("referral|myReferral.description")}
        actions={
          filteredProjects && (
            <Div
              flex={"row"}
              tablet={{ gap: 8, style: { height: "auto" } }}
              fitContent
              gap={16}
              style={{ height: 24 }}
            >
              <SortBySelect />

              <OrderBySelect />
            </Div>
          )
        }
        breadcrumbs={[
          {
            breadcrumbName: t("navigation.home"),
            path: "/",
          },
          {
            breadcrumbName: t("navigation.myReferral"),
          },
        ]}
      >
        <ProjectSummary projects={referralProjects} />
        <ConfigProvider renderEmpty={renderEmpty}>
          <Container>
            <List
              pagination={{ pageSize: 10, align: "center" }}
              grid={{ column: 1, gutter: 20 }}
              dataSource={filteredProjects}
              renderItem={(project) => (
                <List.Item>
                  <ReferralProjectCard project={project} key={project.rfp_id} />
                </List.Item>
              )}
            />
          </Container>
        </ConfigProvider>
      </PageLayout>
    </>
  );
};

export default ReferralViewPage;

