import {
  AssetFolderEntity,
  AssetItemType,
} from "../../../features/entities/assets-entities";

export const isAssetFolder = (item: AssetItemType) =>
  !!(item as AssetFolderEntity)?.record_name;

export default isAssetFolder;

