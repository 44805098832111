import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import { prefixHTTPS } from "../../../../features/helpers/prefix-https";
import MenuItemLabel, { NavigationMenuLabelProps } from "./menu-item-label";

const NavigationMenuLabelWrapper = (props: NavigationMenuLabelProps) => {
  const {
    isExternal,
    external_link,
    pathname,
    subLinks,
    onClick,
    handleLinkClick,
    handleListItemsOpening,
  } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  const { pathname: activePathname } = useLocation();

  const isCurrent = useMemo(
    () => pathname === activePathname,
    [pathname, activePathname]
  );

  return (!!subLinks && !isTablet) || onClick ? (
    <div
      onClick={
        !!subLinks
          ? handleListItemsOpening
          : () => handleLinkClick && handleLinkClick(props, subLinks)
      }
    >
      <MenuItemLabel {...props} isCurrent={isCurrent} />
    </div>
  ) : isExternal && !!external_link ? (
    <a
      href={prefixHTTPS(external_link, "navigation", "partner-app")}
      rel={`nofollow noopener noreferrer`}
      target="__blank"
      style={{ width: "100%", display: "flex" }}
    >
      <MenuItemLabel {...props} isCurrent={isCurrent} />
    </a>
  ) : pathname && !isExternal ? (
    <Link to={pathname} style={{ width: "100%", display: "flex" }}>
      <MenuItemLabel {...props} isCurrent={isCurrent} />
    </Link>
  ) : (
    <MenuItemLabel {...props} isCurrent={isCurrent} />
  );
};

export default NavigationMenuLabelWrapper;

