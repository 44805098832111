import Doc from "@hellodarwin/icons/dist/icons/Doc";
import Help from "@hellodarwin/icons/dist/icons/Help";
import Image from "@hellodarwin/icons/dist/icons/Image";
import PDF from "@hellodarwin/icons/dist/icons/PDF";
import Projects from "@hellodarwin/icons/dist/icons/Projects";
import Spreadsheet from "@hellodarwin/icons/dist/icons/Spreadsheet";
import {
  AssetEntity,
  AssetItemType,
} from "../../../features/entities/assets-entities";
import isAssetFolder from "./is-asset-folder";

const getFileIcon = (asset: AssetItemType) => {
  if (isAssetFolder(asset)) {
    return Projects;
  } else {
    if (!asset) return Help;
    const file = asset as AssetEntity;

    switch (file.extension) {
      case ".png":
      case ".jpg":
      case ".jpeg":
      case ".gif":
        return Image;
      case ".pdf":
        return PDF;
      case ".xls":
      case ".csv":
        return Spreadsheet;
      case ".doc":
        return Doc;
      default:
        return Help;
        break;
    }
  }
};

export default getFileIcon;

