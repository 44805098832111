import { Token } from "@stripe/stripe-js/types/api/tokens";
import { AppName } from "./core-entities";
import { Project, ProjectInitialState } from "./project-entities";
import { Provider, ProviderInitialState } from "./providers-entities";
import { ReviewResult } from "./reviews-entities";

export interface Match {
  match_id: string;
  rfp_id: string;
  provider_id: string;
  provider_contact_id: string;
  status?: string;
  lead_price?: number;
  raise_hand_price?: number;
  discounted_amount?: number;
  visible_at?: string;
  winner_at: string | null;
  won_source?: string;
  rejected_at?: string | null;
  reject_reason?: string;
  refused_at?: string | null;
  refused_reason?: string;
  purchased_at: string | null;
  shortlisted_at: string;
  shortlist_source?: AppName;
  shortlist_message: string;
  archived_at?: string | null;
  raise_hand_rejected_at: string;
  raise_hand_reject_reason?: string;
  raise_hand_reject_reason_specified?: string;
  raise_hand_reject_reason_source?: AppName;
  raise_hand_source?: AppName;
  provider: Provider;
  hello_message: string;
  project: Project;
  raised_hand_at: string | null;
  created_at?: string;
  price?: string;
  score?: number;
  source?: string;
  project_company_name?: string;
  project_contact_name?: string;
  project_contact_email?: string;
  provider_company_name?: string;
  provider_company_email?: string;
  provider_contact_name?: string;
  provider_contact_email?: string;
  provider_contact_phone?: string;
  blacklisted_at?: string;
  viewed_at?: string;
  reviews?: ReviewResult[];
  win_fee_invoice_id?: string;
  win_fee_charged_at?: string;
  win_fee_source?: string;
  win_fee_price?: number;
  updated_at?: string;
  billing_invoice_id?: string;
  price_paid?: number;
  matchmaking_manifest_id?: string;
  rh_price_waived_at?: string;
  shortlist_count?: number;
  raised_hand_count?: number;
}

export const MatchInitialState: Match = {
  match_id: "",
  rfp_id: "",
  provider_id: "",
  provider_contact_id: "",
  winner_at: "",
  purchased_at: "",
  shortlisted_at: "",
  shortlist_message: "",
  raise_hand_rejected_at: "",
  provider: ProviderInitialState,
  hello_message: "",
  raised_hand_at: "",
  project: ProjectInitialState,
};

export interface MatchPrice {
  discount: number;
  total: number;
}

export interface MappedMatches {
  new: { [key: string]: Match };
  onGoing: { [key: string]: Match };
  completed: { [key: string]: Match };
}

export interface MappedClientMatches {
  all: { [key: string]: Match };
  candidates: { [key: string]: Match };
  shortlist: { [key: string]: Match };
  rejected: { [key: string]: Match };
  hasWinner: boolean;
}

export interface RaiseHandParams {
  matchId?: string;
  rfpId: string;
  providerId: string;
  providerContactId: string;
  price: number;
  raiseHandPrice: number;
  helloMessage: string;
  waiveRhPrice: boolean;
  token?: Token;
}

export interface ShortlistParams {
  matchId?: string;
  rfpId: string;
  providerId: string;
  shortlist_message: string;
  source: string;
}

export interface WinFeeParams {
  matchId: string;
  rfpId: string;
  providerId: string;
  price: number;
}

