import Drawer from "antd/es/drawer";
import { AssetRowMeta } from "../../../../features/entities/assets-entities";
import Typography from "../../../common/typography";
import AssetDrawerInfos from "./asset-viewer-infos";

interface AssetViewerDrawerProps {
  open: boolean;
  onClose: () => void;
  meta: AssetRowMeta;
}

const AssetViewerDrawer: React.FC<AssetViewerDrawerProps> = ({
  open,
  onClose,
  meta,
}) => {
  if (!meta) return <></>;

  const { name } = meta;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      styles={{
        header: { overflow: "hidden", flex: "0 0 auto", padding: "8px 24px" },
      }}
      title={
        <Typography
          lineClamp={1}
          elementTheme="subtitle1"
          style={{ width: "100%" }}
        >
          {name}
        </Typography>
      }
    >
      <AssetDrawerInfos {...meta} />
    </Drawer>
  );
};

export default AssetViewerDrawer;

