import Container from "@hellodarwin/core/lib/components/common/container";
import Div from "@hellodarwin/core/lib/components/common/div";
import HdTag from "@hellodarwin/core/lib/components/common/hd-tag";
import Table from "@hellodarwin/core/lib/components/common/table";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { ReviewResult } from "@hellodarwin/core/lib/features/entities";
import { getShortFormattedDate } from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import RecommendNo from "@hellodarwin/icons/dist/icons/RecommendNo";
import RecommendYes from "@hellodarwin/icons/dist/icons/RecommendYes";
import Avatar from "antd/es/avatar";
import AntdTable, { ColumnsType } from "antd/es/table";
import Tooltip from "antd/es/tooltip";
import { ReactNode } from "react";
import { useAppSelector } from "../../app/app-hooks";
import { selectReviewsLoading } from "../../features/api/slices/reviews-slice";
interface ReviewsTableProps {
  reviews: ReviewResult[];
}
const ReviewsTable = ({ reviews }: ReviewsTableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isLoading = useAppSelector(selectReviewsLoading);

  const data: ReviewResult[] = reviews.map((review, index) => ({
    ...review,
    key: index,
    contact_name: review.contact_name || "",
    project_service: review.project_service || "",
    average: review.average || 0,
    reviewed_at: getShortFormattedDate(review.reviewed_at),
    description_summary: review.description_summary || "",
    notice: review?.description_summary || "",
    recommend: review.recommend || false,
    rating_collaboration: review.rating_collaboration || 0,
    rating_quality: review.rating_quality || 0,
    rating_schedule: review.rating_schedule || 0,
    rating_budget: review.rating_budget || 0,
  }));

  const Label = ({ v, medium }: { v: string; medium?: boolean }) => {
    return (
      <Tooltip title={v}>
        <div>
          <Typography nowrap ellipsis medium={medium}>
            {v}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  const columns: ColumnsType<ReviewResult> = [
    {
      title: t("reviews|card.user"),
      dataIndex: "contact_name",
      key: "contact_name",
      render: (v) => (
        <Div flex="row" gap={16} align="center">
          <Avatar shape="circle" size={50} style={{ flexShrink: 0 }}>
            {v[0]}
          </Avatar>
          <Label v={v} medium />
        </Div>
      ),
      sorter: {
        compare: (a, b) => a.contact_name.localeCompare(b.contact_name),
      },
    },
    {
      title: t("reviews|card.serviceGiven"),
      dataIndex: "project_service",
      key: "project_service",
      render: (_text, record) => (
        <HdTag
          color={theme.colors.purple_2}
          text={record.project_service}
          translateTag
        />
      ),
      sorter: {
        compare: (a, b) => a.project_service.localeCompare(b.project_service),
      },
    },
    {
      title: t("reviews|card.assessment"),
      dataIndex: "average",
      key: "average",
      width: 120,
      render: (_text, record) => (
        <Div flex="row" align="center" justify="center" gap={8}>
          <RatingFill size={24} color={theme.colors.yellow_star} />
          <Typography elementTheme="subtitle1">{record.average}</Typography>
        </Div>
      ),
      sorter: {
        compare: (a, b) => (a?.average ?? 0) - (b?.average ?? 0),
      },
    },
    {
      title: t("reviews|card.notice"),
      dataIndex: "notice",
      key: "notice",
      render: (_text, record) => <Label v={record.description_summary ?? ""} />,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: t("reviews|card.date"),
      dataIndex: "reviewed_at",
      key: "reviewed_at",
      render: (_text, record) => <Label v={record.reviewed_at ?? ""} />,
      width: 150,
      sorter: {
        compare: (a, b) =>
          new Date(a.reviewed_at || 0).getTime() -
          new Date(b.reviewed_at || 0).getTime(),
      },
    },
    AntdTable.EXPAND_COLUMN,
  ];

  const InfoRow = ({
    label,
    value,
    prefix,
  }: {
    label: string;
    value: string | ReactNode;
    prefix: string | ReactNode;
  }) => (
    <Div style={{ flex: 1 }} flex="column" gap={8}>
      <Typography elementTheme="overline" color={theme.colors.grey_2} nowrap>
        {label}
      </Typography>
      <Div flex="row" gap={12} align="center">
        {prefix}
        <Typography elementTheme="body1" nowrap>
          {value}
        </Typography>
      </Div>
    </Div>
  );

  const ExpandRowRender = (record: ReviewResult) => (
    <Div flex="row" gap={24}>
      <Typography
        color={theme.colors.grey_2}
        elementTheme="body2"
        style={{ flex: 2 }}
      >
        {record.description_summary}
      </Typography>
      <InfoRow
        label={t("reviews|card.recommend")}
        value={
          record.recommend
            ? t("reviews|newReview.recommend.yes")
            : t("reviews|newReview.recommend.no")
        }
        prefix={
          record.recommend ? (
            <RecommendYes
              width={24}
              height={24}
              style={{
                color: theme.colors.green_1,
              }}
            />
          ) : (
            <RecommendNo
              width={24}
              height={24}
              style={{
                color: theme.colors.error,
              }}
            />
          )
        }
      />
      {record.rating_quality !== undefined && record.rating_quality !== 0 && (
        <InfoRow
          label={t("reviews|card.rating.quality")}
          value={`${record.rating_quality} / 5`}
          prefix={
            <RatingFill
              width={24}
              height={24}
              color={theme.colors.yellow_star}
            />
          }
        />
      )}
      {record.rating_collaboration !== undefined &&
        record.rating_collaboration !== 0 && (
          <InfoRow
            label={t("reviews|card.rating.collaboration")}
            value={`${record.rating_collaboration} / 5`}
            prefix={
              <RatingFill
                width={24}
                height={24}
                color={theme.colors.yellow_star}
              />
            }
          />
        )}
      {record.rating_schedule !== undefined && record.rating_schedule !== 0 && (
        <InfoRow
          label={t("reviews|card.rating.schedule")}
          value={`${record.rating_schedule} / 5`}
          prefix={
            <RatingFill
              width={24}
              height={24}
              color={theme.colors.yellow_star}
            />
          }
        />
      )}
      {record.rating_budget !== undefined && record.rating_budget !== 0 && (
        <InfoRow
          label={t("reviews|card.rating.budget")}
          value={`${record.rating_budget} / 5`}
          prefix={
            <RatingFill
              width={24}
              height={24}
              color={theme.colors.yellow_star}
            />
          }
        />
      )}
    </Div>
  );

  return (
    <Container>
      <Typography.Title level={2} elementTheme="h5">
        {t("navigation.reviews")}
      </Typography.Title>
      <Table
        sticky
        dataSource={data}
        columns={columns}
        rowKey={(record: ReviewResult) => record.review_id}
        scroll={{ x: 1200 }}
        expandable={{
          expandedRowRender: ExpandRowRender,
        }}
        pagination={{ position: ["bottomCenter"] }}
        loading={isLoading}
      />
    </Container>
  );
};

export default ReviewsTable;

