import styled, { css } from "../../../plugins/styled";
import { DefaultTheme } from "../../../theme";

type StyledTagProps = {
  $color?: string;
  $size?: "small" | "medium" | "large";
  $shape?: "round" | "square";
  $hoverable?: boolean;
  $highContrastText?: boolean;
  $withBorder?: boolean;
};

interface TagTheme {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  backgroundColorHover: string;
  borderColorHover: string;
  textColorHover: string;
}

export interface StatusTagTheme extends TagTheme {
  svgBackground: string;
  svgBorder: string;
}

const getTagTheme = (theme: DefaultTheme, color?: string) => {
  const allTagsThemes: { [name: string]: TagTheme } = {
    [theme.colors.purple_1]: {
      backgroundColor: theme.colors.purple_1,
      borderColor: theme.colors.purple_5,
      textColor: theme.colors.purple_5,
      backgroundColorHover: theme.colors.purple_2,
      borderColorHover: theme.colors.purple_5,
      textColorHover: theme.colors.purple_5,
    },
    [theme.colors.purple_2]: {
      backgroundColor: theme.colors.purple_2,
      borderColor: theme.colors.purple_1,
      textColor: theme.colors.white_1,
      backgroundColorHover: theme.colors.purple_3,
      borderColorHover: theme.colors.purple_1,
      textColorHover: theme.colors.white_1,
    },
    [theme.colors.purple_3]: {
      backgroundColor: theme.colors.purple_3,
      borderColor: theme.colors.purple_2,
      textColor: theme.colors.white_1,
      backgroundColorHover: theme.colors.purple_4,
      borderColorHover: theme.colors.purple_2,
      textColorHover: theme.colors.purple_1,
    },
    [theme.colors.purple_4]: {
      backgroundColor: theme.colors.purple_4,
      borderColor: theme.colors.purple_3,
      textColor: theme.colors.purple_2,
      backgroundColorHover: theme.colors.purple_2,
      borderColorHover: theme.colors.purple_3,
      textColorHover: theme.colors.white_1,
    },
    [theme.colors.purple_5]: {
      backgroundColor: theme.colors.purple_5,
      borderColor: theme.colors.purple_4,
      textColor: theme.colors.purple_1,
      backgroundColorHover: theme.colors.purple_3,
      borderColorHover: theme.colors.purple_4,
      textColorHover: theme.colors.purple_5,
    },
    [theme.colors.yellow_1]: {
      backgroundColor: theme.colors.yellow_1,
      borderColor: theme.colors.yellow_3,
      textColor: theme.colors.primary,
      backgroundColorHover: theme.colors.yellow_2,
      borderColorHover: theme.colors.yellow_3,
      textColorHover: theme.colors.primary,
    },
    [theme.colors.yellow_2]: {
      backgroundColor: theme.colors.yellow_2,
      borderColor: theme.colors.yellow_1,
      textColor: theme.colors.primary,
      backgroundColorHover: theme.colors.yellow_3,
      borderColorHover: theme.colors.yellow_1,
      textColorHover: theme.colors.primary,
    },
    [theme.colors.yellow_3]: {
      backgroundColor: theme.colors.yellow_3,
      borderColor: theme.colors.yellow_2,
      textColor: theme.colors.primary,
      backgroundColorHover: theme.colors.yellow_1,
      borderColorHover: theme.colors.yellow_2,
      textColorHover: theme.colors.primary,
    },
    [theme.colors.yellow_4]: {
      backgroundColor: theme.colors.yellow_4,
      borderColor: theme.colors.yellow_3,
      textColor: theme.colors.primary,
      backgroundColorHover: theme.colors.yellow_2,
      borderColorHover: theme.colors.yellow_3,
      textColorHover: theme.colors.primary,
    },
    [theme.colors.grey_1]: {
      backgroundColor: theme.colors.grey_1,
      borderColor: theme.colors.grey_4,
      textColor: theme.colors.white_1,
      backgroundColorHover: theme.colors.grey_2,
      borderColorHover: theme.colors.grey_4,
      textColorHover: theme.colors.white_1,
    },
    [theme.colors.grey_2]: {
      backgroundColor: theme.colors.grey_2,
      borderColor: theme.colors.grey_4,
      textColor: theme.colors.white_1,
      backgroundColorHover: theme.colors.grey_3,
      borderColorHover: theme.colors.grey_4,
      textColorHover: theme.colors.white_1,
    },
    [theme.colors.grey_3]: {
      backgroundColor: theme.colors.grey_3,
      borderColor: theme.colors.grey_1,
      textColor: theme.colors.white_1,
      backgroundColorHover: theme.colors.grey_2,
      borderColorHover: theme.colors.grey_1,
      textColorHover: theme.colors.white_1,
    },
    [theme.colors.grey_4]: {
      backgroundColor: theme.colors.grey_4,
      borderColor: theme.colors.grey_2,
      textColor: theme.colors.white_1,
      backgroundColorHover: theme.colors.grey_3,
      borderColorHover: theme.colors.grey_2,
      textColorHover: theme.colors.white_1,
    },
    [theme.colors.grey_5]: {
      backgroundColor: theme.colors.grey_5,
      borderColor: theme.colors.grey_3,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.grey_4,
      borderColorHover: theme.colors.grey_3,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.green_1]: {
      backgroundColor: theme.colors.green_1,
      borderColor: theme.colors.green_4,
      textColor: theme.colors.green_4,
      backgroundColorHover: theme.colors.green_2,
      borderColorHover: theme.colors.green_4,
      textColorHover: theme.colors.green_4,
    },
    [theme.colors.green_2]: {
      backgroundColor: theme.colors.green_2,
      borderColor: theme.colors.green_4,
      textColor: theme.colors.green_4,
      backgroundColorHover: theme.colors.green_1,
      borderColorHover: theme.colors.green_4,
      textColorHover: theme.colors.green_4,
    },
    [theme.colors.green_3]: {
      backgroundColor: theme.colors.green_3,
      borderColor: theme.colors.green_1,
      textColor: theme.colors.green_1,
      backgroundColorHover: theme.colors.green_2,
      borderColorHover: theme.colors.green_4,
      textColorHover: theme.colors.green_4,
    },
    [theme.colors.green_4]: {
      backgroundColor: theme.colors.green_4,
      borderColor: theme.colors.green_1,
      textColor: theme.colors.green_1,
      backgroundColorHover: theme.colors.green_1,
      borderColorHover: theme.colors.green_4,
      textColorHover: theme.colors.green_4,
    },
    [theme.colors.blue_1]: {
      backgroundColor: theme.colors.blue_4,
      borderColor: theme.colors.blue_1,
      textColor: theme.colors.blue_1,
      backgroundColorHover: theme.colors.blue_1,
      borderColorHover: theme.colors.blue_4,
      textColorHover: theme.colors.blue_4,
    },
    [theme.colors.blue_4]: {
      backgroundColor: theme.colors.blue_1,
      borderColor: theme.colors.blue_4,
      textColor: theme.colors.blue_4,
      backgroundColorHover: theme.colors.blue_4,
      borderColorHover: theme.colors.blue_1,
      textColorHover: theme.colors.blue_1,
    },
    [theme.colors.orange_1]: {
      backgroundColor: theme.colors.orange_4,
      borderColor: theme.colors.orange_1,
      textColor: theme.colors.orange_1,
      backgroundColorHover: theme.colors.orange_1,
      borderColorHover: theme.colors.orange_4,
      textColorHover: theme.colors.orange_4,
    },
    [theme.colors.orange_4]: {
      backgroundColor: theme.colors.orange_1,
      borderColor: theme.colors.orange_4,
      textColor: theme.colors.orange_4,
      backgroundColorHover: theme.colors.orange_4,
      borderColorHover: theme.colors.orange_1,
      textColorHover: theme.colors.orange_1,
    },
    [theme.colors.red_1]: {
      backgroundColor: theme.colors.red_1,
      borderColor: theme.colors.red_1,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.red_1,
      borderColorHover: theme.colors.red_4,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.red_5]: {
      backgroundColor: theme.colors.red_5,
      borderColor: theme.colors.red_5,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.red_5,
      borderColorHover: theme.colors.red_4,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.beige_1]: {
      backgroundColor: theme.colors.beige_1,
      borderColor: theme.colors.beige_3,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.beige_2,
      borderColorHover: theme.colors.beige_3,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.beige_2]: {
      backgroundColor: theme.colors.beige_2,
      borderColor: theme.colors.beige_1,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.beige_3,
      borderColorHover: theme.colors.beige_1,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.beige_3]: {
      backgroundColor: theme.colors.beige_3,
      borderColor: theme.colors.beige_2,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.beige_4,
      borderColorHover: theme.colors.beige_2,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.beige_4]: {
      backgroundColor: theme.colors.beige_4,
      borderColor: theme.colors.beige_3,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.beige_1,
      borderColorHover: theme.colors.beige_3,
      textColorHover: theme.colors.grey_1,
    },
    [theme.colors.transparent]: {
      backgroundColor: theme.colors.transparent,
      borderColor: theme.colors.transparent,
      textColor: theme.colors.grey_1,
      backgroundColorHover: theme.colors.transparent,
      borderColorHover: theme.colors.transparent,
      textColorHover: theme.colors.grey_2,
    },
  };

  return allTagsThemes[color ?? theme.colors.purple_5];
};

const getColorStyling = (type: "normal" | "hover") => {
  return css<StyledTagProps>`
    ${({ $color, theme, $withBorder, $highContrastText }) => {
      const tagTheme =
        getTagTheme(theme as DefaultTheme, $color) ||
        getTagTheme(theme as DefaultTheme, theme.colors.purple_4);

      const borderColor = $withBorder
        ? type === "normal"
          ? tagTheme.borderColor
          : tagTheme.borderColorHover
        : type === "normal"
          ? tagTheme.backgroundColor
          : tagTheme.backgroundColorHover;

      const textColor = $highContrastText
        ? theme.colors.grey_2
        : type === "normal"
          ? tagTheme.textColor
          : tagTheme.textColorHover;
      return `
      color:  ${textColor};
      background: ${
        type === "normal"
          ? tagTheme.backgroundColor
          : tagTheme.backgroundColorHover
      };
      border: 1px solid ${borderColor};
    `;
    }};
  `;
};

const hoverableStyling = css<StyledTagProps>`
  &:hover {
    cursor: pointer;
    scale: 1.02;

    ${getColorStyling("hover")}
  }
`;

const smallStyling = css<StyledTagProps>`
  padding: 3px 8px;
  gap: 4px;
  font-size: 10px;
`;

const mediumStyling = css<StyledTagProps>`
  padding: 5px 11px;
  gap: 6px;
  font-size: 11px;
`;

const largeStyling = css<StyledTagProps>`
  padding: 8px 14px;
  gap: 6px;
  font-size: 13px;
`;

export const StyledTag = styled.div<StyledTagProps>`
  border-radius: ${(props) => (props.$shape === "square" ? "4px" : "24px")};
  display: inline-flex;
  align-items: center;
  gap: 6px;
  max-width: 100%;

  font-weight: 500;
  letter-spacing: 0.3px;
  width: fit-content;
  max-width: 100%;
  line-height: 1.1;
  text-transform: uppercase;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${getColorStyling("normal")}
  ${({ $hoverable }) => ($hoverable ? hoverableStyling : undefined)}
  ${(props) =>
    props.$size === "small"
      ? smallStyling
      : props.$size === "large"
        ? largeStyling
        : mediumStyling}
`;

