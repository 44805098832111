import RatingFill from "@hellodarwin/icons/dist/icons/RatingFill";
import { ReviewResult } from "../../../../features/entities";
import { useTheme } from "../../../../plugins/styled";
import Div from "../../../common/div";
import Typography from "../../../common/typography";

const ReviewOverallScore = ({ review }: { review: ReviewResult }) => {
  const theme = useTheme();
  return (
    <Div
      flex="row"
      align="center"
      justify={"center"}
      tablet={{ flex: "column" }}
    >
      <RatingFill size={24} color={theme.colors.yellow_star} />
      <Typography
        elementTheme="h5"
        nowrap
        style={{ paddingLeft: "1rem" }}
        tablet={{ style: { paddingLeft: "0.1rem" } }}
      >
        {review.average}
      </Typography>
    </Div>
  );
};

export default ReviewOverallScore;

