import { useMemo } from "react";
import { AssetOwnerInformation } from "../../../../features/entities/assets-entities";
import getShortId from "../../../../features/helpers/get-short-id";
import { useTranslations } from "../../../../features/providers/translations-provider";

const useOwnerLabelAndPath = (owner: AssetOwnerInformation | undefined) => {
  const { t } = useTranslations();
  const path = useMemo(() => {
    if (!owner?.owner_type) return "";
    switch (owner.owner_type) {
      case "company":
        return `/companies/${owner.owner_id}`;
      case "admin":
        return `/admins`;
      case "contact":
        return `/companies`;
      case "provider":
        return `/providers/${owner.owner_id}`;
      default:
        return "/";
    }
  }, [owner]);

  const label = useMemo(() => {
    if (!owner?.owner_type) return "";

    switch (owner.owner_type) {
      case "contact":
        return (
          t("files_gin|contact_of_company", {
            contact_name: owner.owner_name ?? getShortId(owner.owner_id),
            company_name: owner.owner_relation ?? "-",
          }) ?? owner.owner_id
        );
      default:
        return owner.owner_name ?? owner.owner_id;
    }
  }, [owner, t]);

  return useMemo(() => ({ label, path }), [path, label]);
};
export default useOwnerLabelAndPath;

