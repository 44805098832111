import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const NotFound = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 30L21.2048 22.2048M21.2048 22.2048C23.3146 20.0951 24.4999 17.2336 24.4999 14.2499C24.4999 11.2663 23.3146 8.4048 21.2048 6.29503C19.0951 4.18526 16.2336 3 13.2499 3C10.2663 3 7.4048 4.18526 5.29503 6.29503C3.18526 8.4048 2 11.2663 2 14.2499C2 17.2336 3.18526 20.0951 5.29503 22.2048C7.4048 24.3146 10.2663 25.4999 13.2499 25.4999C16.2336 25.4999 19.0951 24.3146 21.2048 22.2048Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75 9.75C8.75 9.75 11.4926 12.4926 13.25 14.25M13.25 14.25C15.0074 16.0074 17.75 18.75 17.75 18.75M13.25 14.25C15.0074 12.4926 17.75 9.75 17.75 9.75M13.25 14.25C11.4926 16.0074 8.75 18.75 8.75 18.75"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotFound;

