import { FormInstance } from "antd/es/form";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AddressInformations,
  AddressInformationsInitialState,
} from "../../../../features/entities";
import { useTranslation } from "../../../../plugins/i18n";
import { StyledCustomInput } from "../../form-items/custom-input/styles";
import { getLongAddress, getShortAddress } from "../../utils/get-address";

interface GoogleAutocompleteProps {
  form: FormInstance<any>;
  setCanada: Dispatch<SetStateAction<boolean>>;
}

const GoogleAutocomplete = ({ form, setCanada }: GoogleAutocompleteProps) => {
  const { t } = useTranslation();
  const input = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(form.getFieldValue("complete"));

  useEffect(() => {
    setTimeout(() => {
      setValue(form.getFieldValue("complete"));
    }, 500);
  }, []);

  const onPlaceSelected = (
    address: google.maps.GeocoderAddressComponent[],
    geometry: google.maps.places.PlaceGeometry
  ) => {
    if (!!address) {
      const addressInfos: AddressInformations = address.reduce((o, value) => {
        return {
          ...o,
          [value.types[0]]: ["administrative_area_level_1"].includes(
            value.types[0]
          )
            ? value.short_name
            : value.long_name,
        };
      }, AddressInformationsInitialState);

      const short = getShortAddress(addressInfos);
      const complete = getLongAddress(short, {
        city: addressInfos.locality,
        address: short,
        country: addressInfos.country,
        postal_code: addressInfos.postal_code,
        province: addressInfos.administrative_area_level_1,
      });

      setValue(complete);
      setCanada(addressInfos.country == "Canada");

      form.setFieldsValue({
        address: getShortAddress(addressInfos),
        city: addressInfos.locality,
        country: addressInfos.country,
        isCanada: addressInfos.country == "Canada",
        province: addressInfos.administrative_area_level_1,
        postal_code: addressInfos.postal_code,
        lng: geometry.location?.lng(),
        lat: geometry.location?.lat(),
        complete,
      });
    }
  };

  useEffect(() => {
    if (!input.current) return;
    const center = { lat: 50.064192, lng: -130.605469 };
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };

    const options: google.maps.places.AutocompleteOptions = {
      bounds: defaultBounds,
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      componentRestrictions: { country: ["ca", "us", "fr"] },
      types: ["street_address", "locality"],
    };

    const autocomplete = new google.maps.places.Autocomplete(
      input.current,
      options
    );
    autocomplete?.setFields(["place_id", "geometry", "name"]);

    autocomplete.addListener("place_changed", async () => {
      const place = autocomplete.getPlace();
      onPlaceSelected(place.address_components ?? [], place.geometry ?? {});
    });
  }, [input]);

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("complete", value);
    setValue(value);
  };

  return (
    <StyledCustomInput
      className="ant-input ant-input-lg"
      ref={input}
      onChange={onChange}
      value={value}
      placeholder={t("profile|profileForm.address.placeholder.addressComplete")}
    />
  );
};

export default GoogleAutocomplete;

