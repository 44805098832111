import styled from "../../../../../plugins/styled";
import Card from "../../../../common/Card";

export const TeamMemberCardContainer = styled(Card)<{ $selected?: boolean }>`
  cursor: pointer;
  border-radius: 0;
  border-width: 1px;
  width: 100%;

  .card-body {
    gap: 24px;
    display: flex;
    align-items: center;
    padding: 16px;
  }

  a {
    width: fit-content;
    border: none;
    color: ${(props) => props.theme.colors.purple_2};
    &:hover {
      color: ${(props) => props.theme.colors.purple_3};
    }
  }

  &:hover {
    border-width: 2px;
    border-color: ${(props) => props.theme.colors.purple_2};
  }
  ${({ $selected, theme }) =>
    $selected
      ? `
      background:${theme.colors.purple_4}; 
      border-color:${theme.colors.purple_2};
      `
      : undefined};
`;

