import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const PDF = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 19.4615V15.4231C27 14.046 26.4785 12.7253 25.5502 11.7516C24.6219 10.7778 23.3628 10.2308 22.05 10.2308H19.85C19.4124 10.2308 18.9927 10.0484 18.6833 9.72384C18.3738 9.39926 18.2 8.95903 18.2 8.5V6.19231C18.2 4.81522 17.6785 3.49454 16.7502 2.52079C15.8219 1.54705 14.5628 1 13.25 1H10.5M13.8 1H6.65C5.7392 1 5 1.77538 5 2.73077V29.2692C5 30.2246 5.7392 31 6.65 31H25.35C26.2608 31 27 30.2246 27 29.2692V14.8462C27 11.1739 25.6093 7.6521 23.1338 5.05544C20.6583 2.45879 17.3009 1 13.8 1Z"
        strokeWidth={strokeWidth}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 27.3292V21.27H10.3993C10.8908 21.27 11.3032 21.3568 11.6364 21.5304C11.9717 21.7039 12.2247 21.9426 12.3955 22.2464C12.5684 22.5481 12.6548 22.8913 12.6548 23.2759C12.6548 23.6645 12.5684 24.0097 12.3955 24.3114C12.2227 24.6132 11.9675 24.8509 11.6301 25.0245C11.2927 25.1961 10.8772 25.2819 10.3836 25.2819H8.79351V24.3795H10.2274C10.5149 24.3795 10.7502 24.3321 10.9335 24.2375C11.1168 24.1428 11.2521 24.0126 11.3396 23.8469C11.4292 23.6813 11.4739 23.4909 11.4739 23.2759C11.4739 23.0609 11.4292 22.8716 11.3396 22.7079C11.2521 22.5442 11.1157 22.417 10.9303 22.3262C10.7471 22.2335 10.5107 22.1872 10.2212 22.1872H9.15902V27.3292H8Z"
        fill="currentColor"
      />
      <path
        d="M16.0935 27.3292H13.9255V21.27H16.1373C16.7725 21.27 17.3182 21.3913 17.7743 21.6339C18.2325 21.8746 18.5844 22.2207 18.8302 22.6724C19.076 23.1241 19.1988 23.6645 19.1988 24.2937C19.1988 24.9249 19.0749 25.4673 18.8271 25.9209C18.5813 26.3746 18.2262 26.7227 17.7618 26.9653C17.2994 27.2079 16.7433 27.3292 16.0935 27.3292ZM15.0845 26.3795H16.0373C16.483 26.3795 16.8548 26.3026 17.1526 26.1487C17.4504 25.9929 17.6743 25.7611 17.8243 25.4535C17.9742 25.1438 18.0492 24.7572 18.0492 24.2937C18.0492 23.8302 17.9742 23.4456 17.8243 23.1398C17.6743 22.8321 17.4525 22.6024 17.1588 22.4505C16.8673 22.2966 16.5049 22.2197 16.0717 22.2197H15.0845V26.3795Z"
        fill="currentColor"
      />
      <path
        d="M20.564 27.3292V21.27H24.6627V22.1901H21.723V23.8351H24.3816V24.7552H21.723V27.3292H20.564Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PDF;

