import { CSSProperties, MouseEventHandler, ReactNode } from "react";

export interface IconProps {
  /**
   * @deprecated Use size instead.
   */
  width?: string | number;
  /**
   * @deprecated Use size instead.
   */
  height?: string | number;
  size?: number;
  color?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
}

export type IconNode = (props: IconProps) => ReactNode;

export type LogoType = "primary" | "secondary" | "tertiary";
export type LogoColor = "b/w" | "colour";
export type LogoBackgroundType = "light" | "dark";

export interface HdLogoProps {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
  type?: LogoType;
  color?: LogoColor;
  backgroundType: LogoBackgroundType;
}

export interface LogoProps extends HdLogoProps {
  primaryColor?: string;
  secondaryColor?: string;
}

export const theme = {
  grey_1: "#2b2b2b",
  white_1: "#ffffff",
  purple_1: "#5d0b71",
  purple_2: "#89178f",
  purple_3: "#b861d8",
};
