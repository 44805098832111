import Empty from "antd/es/empty";
import classNames from "classnames";
import { isEqual } from "lodash";
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { useTranslations } from "../../../features/providers/translations-provider";
import Pagination, { PaginationConfig } from "../pagination";
import { ListItem } from "./list-item";
import { ListContainer, ListContent } from "./styles";

export type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm";
export type ColumnCount = number;
export type ListItemLayout = "horizontal" | "vertical";
export type Gutter = number | undefined | Partial<Record<Breakpoint, number>>;

export interface ListGridType {
  gutter?: Gutter;
  column?: ColumnCount;
  sm?: ColumnCount;
  md?: ColumnCount;
  lg?: ColumnCount;
  xl?: ColumnCount;
  xxl?: ColumnCount;
}

export interface ListProps<T> {
  bordered?: boolean;
  divider?: boolean;
  className?: string;
  rootClassName?: string;
  id?: string;
  layout?: ListItemLayout;
  mobileLayout?: ListItemLayout;
  styles?: {
    content?: CSSProperties;
    container?: CSSProperties;
  };
  dataSource: T[];
  grid?: ListGridType;
  loading?: boolean;
  renderItem?: (item: T, index: number) => React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  extra?: React.ReactNode;
  renderEmpty?: () => React.ReactNode;
  pagination?: PaginationConfig;
}

const List = <T,>({
  bordered = false,
  divider,
  className,
  rootClassName,
  id,
  layout,
  styles,
  dataSource,
  grid,
  loading,
  renderItem,
  header,
  footer,
  extra,
  renderEmpty,
  pagination,
}: ListProps<T>) => {
  const { t } = useTranslations();

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState(dataSource);

  const defaultRenderEmpty = () => (
    <Empty description={t("commonComponents|emptyList")} />
  );
  const ListEmpty = !!renderEmpty ? renderEmpty : defaultRenderEmpty;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
  };

  const paginationConfig: PaginationConfig = useMemo(() => {
    const config: PaginationConfig = {
      total: pagination?.total || items?.length || 0,
      currentPage: pagination?.currentPage || currentPage,
      onPageChange: pagination?.onPageChange || handlePaginationChange,
      pageSize: pagination?.pageSize || currentPageSize,
      align: pagination?.align || "center",
    };
    return config;
  }, [pagination, items, currentPage, currentPageSize]);

  const withPagination: boolean = useMemo(
    () => !!pagination,
    [pagination, paginationConfig]
  );

  const slicedDataSource = useMemo(
    () =>
      !!withPagination && items.length > (paginationConfig.pageSize ?? 0)
        ? items.slice(
            (paginationConfig.currentPage ?? 0) *
              (paginationConfig.pageSize ?? 0),
            ((paginationConfig.currentPage ?? 0) + 1) *
              (paginationConfig.pageSize ?? 0)
          )
        : items,
    [withPagination, paginationConfig, items]
  );

  useEffect(() => {
    if (!isEqual(items, dataSource)) {
      setIsLoading(true);
      setTimeout(() => {
        setItems(dataSource);
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      }, 200);
    }
  }, [dataSource]);
  const classes = classNames(rootClassName, {
    [`fade-out`]: isLoading,
  });

  if (loading) return <></>;
  if (isLoading && !items.length) return <></>;

  return (
    <ListContainer
      $isLoading={isLoading}
      className={classes}
      style={{ width: "100%", ...styles?.container }}
    >
      {header}
      {items?.length ? (
        <>
          <ListContent
            id={id}
            className={className}
            $layout={layout}
            $bordered={bordered}
            $divider={divider}
            style={{ ...styles?.content }}
            $grid={grid}
          >
            {React.Children.toArray(
              slicedDataSource?.map((item, index) =>
                renderItem ? (
                  renderItem(item, index)
                ) : (
                  <React.Fragment key={index}>
                    {JSON.stringify(item)}
                  </React.Fragment>
                )
              )
            )}
          </ListContent>
          {extra}
        </>
      ) : (
        <ListEmpty />
      )}

      {withPagination && <Pagination {...paginationConfig} />}
      {footer}
    </ListContainer>
  );
};

List.Item = ListItem;
export default List;

