import React, { PropsWithChildren, ReactNode, useContext } from "react";

export interface AppDataContextType {
  navCollapsed: boolean;
  handleNavCollapse: () => void;
  setNavCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  header: ReactNode;
  setHeader: (node: ReactNode) => void;
  nav: ReactNode;
  setNav: (node: ReactNode) => void;
  bottomNav: ReactNode;
  setBottomNav: (node: ReactNode) => void;
}

export const AppDataContext = React.createContext<AppDataContextType>({
  header: <></>,
  setHeader: (props) => {},
  navCollapsed: false,
  handleNavCollapse: () => {},
  nav: <></>,
  setNav: (props) => {},
  bottomNav: <></>,
  setBottomNav: (props) => {},
  setNavCollapsed: () => {},
});

interface AppDataProviderProps extends PropsWithChildren {
  defaultNavCollapsed?: boolean;
}
const AppDataProvider = ({
  children,
  defaultNavCollapsed = false,
}: AppDataProviderProps) => {
  const [header, setHeader] = React.useState<AppDataContextType["header"]>([]);
  const [navCollapsed, setNavCollapsed] =
    React.useState<AppDataContextType["navCollapsed"]>(defaultNavCollapsed);

  const [nav, setNav] = React.useState<AppDataContextType["header"]>([]);
  const [bottomNav, setBottomNav] = React.useState<
    AppDataContextType["header"]
  >([]);

  const handleNavCollapse = () => setNavCollapsed(!navCollapsed);

  return (
    <AppDataContext.Provider
      value={{
        header,
        setHeader,
        navCollapsed,
        handleNavCollapse,
        nav,
        setNav,
        bottomNav,
        setBottomNav,
        setNavCollapsed,
      }}
    >
      {children}
      <div className="modal-root" />
    </AppDataContext.Provider>
  );
};

export const useAppData = () => useContext(AppDataContext);

export default AppDataProvider;

