import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const User = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7421 7.24785C21.7421 8.69861 21.1658 10.0899 20.14 11.1158C19.1142 12.1416 17.7228 12.7179 16.2721 12.7179C14.8213 12.7179 13.43 12.1416 12.4041 11.1158C11.3783 10.0899 10.802 8.69861 10.802 7.24785C10.802 5.7971 11.3783 4.40576 12.4041 3.37992C13.43 2.35408 14.8213 1.77777 16.2721 1.77777C17.7228 1.77777 19.1142 2.35408 20.14 3.37992C21.1658 4.40576 21.7421 5.7971 21.7421 7.24785ZM5.33336 27.8416C5.38023 24.9714 6.55331 22.2346 8.59962 20.2215C10.6459 18.2083 13.4015 17.0801 16.2721 17.0801C19.1426 17.0801 21.8982 18.2083 23.9445 20.2215C25.9908 22.2346 27.1639 24.9714 27.2108 27.8416C23.779 29.4152 20.0474 30.2273 16.2721 30.2222C12.3686 30.2222 8.66354 29.3703 5.33336 27.8416Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default User;

