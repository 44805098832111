import { useMemo } from "react";
import {
  AssetOwnerInformation,
  AssetRecordInformation,
  MappedAssetsMetas,
} from "../../../features/entities/assets-entities";
import { HandleSaveAssetFunction } from "../../../features/helpers/use-on-file-finish-upload";
import AssetEditorDrawer from "./asset-editor-drawer/edit-single-asset-drawer";
import AssetViewerDrawer from "./asset-viewer-drawer";
import MultiAssetsDrawer from "./multi-assets-drawer";

interface AssetDrawerProps {
  open: boolean;
  onClose: () => void;
  metas: MappedAssetsMetas;
  activeMetaId: string;
  isMulti?: boolean;
  isEdit?: boolean;
  handleSaveAsset: HandleSaveAssetFunction;
  records: AssetRecordInformation[];
  owners: AssetOwnerInformation[];
}

const AssetDrawer: React.FC<AssetDrawerProps> = ({
  open,
  onClose,
  metas,
  activeMetaId,
  isMulti,
  isEdit,
  handleSaveAsset,
  records,
  owners,
}) => {
  const selectedMeta = useMemo(() => metas[activeMetaId], [activeMetaId]);

  return isMulti ? (
    <MultiAssetsDrawer
      onClose={onClose}
      open={open}
      metas={metas}
      isEdit={isEdit}
      handleSaveAsset={handleSaveAsset}
      records={records}
      owners={owners}
      activeMetaId={activeMetaId}
    />
  ) : isEdit ? (
    <AssetEditorDrawer
      onClose={onClose}
      open={open}
      meta={selectedMeta}
      handleSaveAsset={handleSaveAsset}
      records={records}
      owners={owners}
    />
  ) : (
    <AssetViewerDrawer onClose={onClose} open={open} meta={selectedMeta} />
  );
};

export default AssetDrawer;

