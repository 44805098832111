import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";

const LineViewFill = (props: IconProps) => {
  const iconProps = useIconProps(props);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-0.000274658 1.14286C-0.000274658 0.511673 0.5114 0 1.14258 0H30.8569C31.4881 0 31.9997 0.511675 31.9997 1.14286V7.58442C31.9997 8.2156 31.4881 8.72727 30.8569 8.72727H1.14258C0.511399 8.72727 -0.000274658 8.2156 -0.000274658 7.58442V1.14286Z"
        fill="currentColor"
      />
      <path
        d="M-0.000274658 12.7792C-0.000274658 12.148 0.5114 11.6364 1.14258 11.6364H30.8569C31.4881 11.6364 31.9997 12.148 31.9997 12.7792V19.2208C31.9997 19.852 31.4881 20.3636 30.8569 20.3636H1.14258C0.511399 20.3636 -0.000274658 19.8519 -0.000274658 19.2208V12.7792Z"
        fill="currentColor"
      />
      <path
        d="M-0.000274658 24.4156C-0.000274658 23.7844 0.5114 23.2727 1.14258 23.2727H30.8569C31.4881 23.2727 31.9997 23.7844 31.9997 24.4156V30.8571C31.9997 31.4883 31.4881 32 30.8569 32H1.14258C0.511399 32 -0.000274658 31.4883 -0.000274658 30.8571V24.4156Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LineViewFill;

