import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import Checkbox from "antd/es/checkbox/Checkbox";
import InputNumber from "antd/es/input-number";
import Typography from "antd/es/typography";
import { useState } from "react";
import { ProfileEditModalFunctions } from "..";
import { ServiceTag } from "../../../../../features/entities";
import { useTranslation } from "../../../../../plugins/i18n";
import { useTheme } from "../../../../../plugins/styled";
import Div from "../../../../common/div";

type ServiceBudgetSelectionRowProps = {
  tag: ServiceTag;
  functions: ProfileEditModalFunctions;
};

const ServiceBudgetSelectionRow = ({
  tag,
  functions,
}: ServiceBudgetSelectionRowProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isChecked, setIsChecked] = useState(tag.max_budget <= 0);
  const handleCheck = () => {
    setIsChecked((previousState) => !previousState);
  };

  const handleDescriptionChange = (text: string) => {
    !!functions.handleServiceChange &&
      functions.handleServiceChange({
        service: tag.tag,
        minBudget: tag.min_budget,
        maxBudget: tag.max_budget,
        description: text,
      });
  };
  const handleMaxBudgetChange = (maxBudget: number | null) => {
    if (maxBudget !== null) {
      !!functions.handleServiceChange &&
        functions.handleServiceChange({
          service: tag.tag,
          minBudget: tag.min_budget,
          maxBudget,
          description: tag.description ?? "",
        });
    }
  };
  const handleMinBudgetChange = (minBudget: number | null) => {
    if (minBudget !== null) {
      !!functions.handleServiceChange &&
        functions.handleServiceChange({
          service: tag.tag,
          minBudget,
          maxBudget: tag.max_budget,
          description: tag.description ?? "",
        });
    }
  };
  return (
    <Div style={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}>
      <Div
        flex={"row"}
        justify={"space-between"}
        tablet={{ flex: "column", align: "flex-start" }}
      >
        <Div
          flex={"row"}
          align={"center"}
          gap={32}
          tablet={{ flex: "column", align: "flex-start" }}
        >
          <Breadcrumb
            style={{ width: "70%" }}
            items={[
              { title: t(`tags|${tag.category}`) },
              { title: t(`tags|${tag.label}`) },
            ]}
          />
          <Div
            flex={"row"}
            align={"flex-end"}
            tablet={{ flex: "column", align: "flex-start" }}
          >
            <Typography.Paragraph
              editable={{
                onChange: handleDescriptionChange,
                maxLength: 200,
                autoSize: { minRows: 1, maxRows: 1 },
              }}
              style={{
                width: "100%",
              }}
              className="body2"
            >
              {tag.description || (
                <span style={{ color: theme.colors.grey_3 }}>
                  {t("provider-profile|expertise.edit.service_description")}
                </span>
              )}
            </Typography.Paragraph>
          </Div>
          <Div fitContent flex="row" align={"center"}>
            <InputNumber
              style={{ marginRight: 16, width: 125 }}
              defaultValue={tag.min_budget}
              value={tag.min_budget}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={handleMinBudgetChange}
              min={0}
              max={2000000000}
            />
            {isChecked ? (
              <Checkbox
                style={{ marginRight: 16, width: 125 }}
                checked={isChecked}
                onClick={handleCheck}
                className={"service-budget-checkbox"}
              >
                {t("provider-profile|expertise.service.unlimited")}
              </Checkbox>
            ) : (
              <InputNumber
                style={{ marginRight: 16, width: 125 }}
                defaultValue={tag.max_budget}
                value={tag.max_budget}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={handleMaxBudgetChange}
                min={0}
                max={2000000000}
              />
            )}
            <Delete
              width={16}
              height={16}
              style={{ color: theme.colors.red_1, cursor: "pointer" }}
              onClick={() =>
                !!functions.toggleServiceTag &&
                functions.toggleServiceTag({ service: tag.tag })
              }
            />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default ServiceBudgetSelectionRow;

