import Badge from "antd/es/badge";
import styled, { css } from "styled-components";
import { DropdownContainer } from "../../common/dropdown/styles";

export interface AssetCardContainerProps {
  $isActive?: boolean;
  $isRow?: boolean;
  $withUpdateInfos?: boolean;
}
export const AssetActionsContainer = styled.div`
  position: relative;

  position: absolute;

  top: 8px;
  right: 8px;
`;

export const AssetCardContainerStyling = css<AssetCardContainerProps>`
  background: ${({ theme }) => theme.colors.white_1};
  &:hover {
    background: ${({ theme }) => theme.colors.beige_1};
  }
`;
const AssetCardContainerActiveStyling = css<AssetCardContainerProps>`
  background: ${({ theme }) => theme.colors.beige_2};
`;

const AssetRowStyling = css<AssetCardContainerProps>`
  display: flex;
  flex-direction: row;

  align-items: center;
  position: relative;
  width: 100%;

  .asset-name {
    flex: 1;
  }
  .asset-type {
    flex: 0 0 260px;
  }
  .asset-date {
    flex: 0 0 260px;
  }
  .asset-size {
    flex: 0 0 160px;
  }
  .asset-icon {
    flex: 0 0 24px;
  }
  .asset-actions {
    flex-shrink: 0;
    flex-grow: 0;
    ${DropdownContainer} {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        z-index: -1;
        backdrop-filter: blur(20px);
        ${({ $isActive }) =>
          $isActive
            ? AssetCardContainerActiveStyling
            : AssetCardContainerStyling}
      }
    }
  }
  & > * {
    overflow: hidden;
    &,
    &.hd-paragraph {
      margin: 8px 12px;
    }
    height: auto;
  }
`;
export const AssetTableHeaderContainer = styled.div<{ $isActive?: boolean }>`
  ${AssetRowStyling}
`;
export const AssetRowContainer = styled.div<AssetCardContainerProps>`
  ${AssetRowStyling}

  cursor: pointer;
  ${({ $isActive }) =>
    $isActive ? AssetCardContainerActiveStyling : AssetCardContainerStyling}
`;

export const AssetBadgeContainer = styled(Badge)`
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  &:hover {
    transform: translateY(-3px);

    cursor: pointer;
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
    backface-visibility: hidden;
  }
`;
export const AssetTileContainer = styled.div<AssetCardContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  width: 300px;
  height: ${({ $withUpdateInfos }) => ($withUpdateInfos ? 240 : 170)}px;
  position: relative;
  padding: 16px 24px;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.grey_4};

  ${({ $isActive }) =>
    $isActive ? AssetCardContainerActiveStyling : AssetCardContainerStyling}
`;

export const AssetIconContainer = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white_1};
`;

