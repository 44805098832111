import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { Collapse as ReactCollapse } from "react-collapse";
import { ExpandIconPosition } from ".";
import useMediaQuery from "../../../features/helpers/use-media-query";
import { useTheme } from "../../../plugins/styled";
import Div from "../div";
import Typography from "../typography";
import { CollapsePanelContainer, CollapsePanelHeader } from "./styles";

export interface CollapsePanelProps extends PropsWithChildren {
  title: PropsWithChildren["children"];
  styles?: {
    container?: CSSProperties;
    header?: CSSProperties;
    content?: CSSProperties;
  };
  isActive?: boolean;
  showArrow?: boolean;
  align?: "start" | "end" | "center";
  ghost?: boolean;
  ExpandIcon?: (panelProps: CollapsePanelExtendedProps) => React.ReactNode;
  expandIconPosition?: ExpandIconPosition;
  expandIconPositionTablet?: ExpandIconPosition;
  handleClickButtonOnly?: boolean;
  extra?: ReactNode;
  headerIsColumnOnTablet?: boolean;
  noPadding?: boolean;
  id: string;
}

export interface CollapsePanelExtendedProps
  extends Omit<CollapsePanelProps, "key"> {
  onClick: (key: string) => void;
  hasGap?: boolean;
}

const CollapseHeader = (props: CollapsePanelExtendedProps) => {
  const {
    children,
    title,
    styles,
    expandIconPosition = "center",
    handleClickButtonOnly,
    id,
    onClick,
    headerIsColumnOnTablet,
  } = props;
  return (
    <CollapsePanelHeader
      $expandIconPosition={expandIconPosition}
      $headerIsColumnOnTablet={headerIsColumnOnTablet}
      onClick={!handleClickButtonOnly ? () => onClick(id) : undefined}
      style={{ ...styles?.header }}
    >
      {typeof title === "string" ? (
        <Typography elementTheme="subtitle2">{title}</Typography>
      ) : (
        title
      )}
      {!!children && !["bottom", "top"].includes(expandIconPosition) && (
        <ExpandButton {...props} />
      )}
    </CollapsePanelHeader>
  );
};

const DefaultExpandIcon = ({
  isActive,
  showArrow = true,
}: CollapsePanelExtendedProps) => {
  return showArrow ? (
    isActive ? (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#2B2B2B" />
        <path
          d="M17.5381 12L6.46115 12"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
        />
      </svg>
    ) : (
      <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.451294" width="24.7742" height="24" rx="12" fill="#2B2B2B" />
        <path
          d="M12.9181 6.46167L12.9181 17.5386"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
        />
        <path
          d="M18.5558 12.0002L7.12156 12.0002"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
        />
      </svg>
    )
  ) : (
    <></>
  );
};

const ExpandButton = (props: CollapsePanelExtendedProps) => {
  const { expandIconPosition, ExpandIcon, onClick, id, extra } = props;

  return (
    <Div flex="row" gap={8} align="center" fitContent>
      {extra}
      <div
        style={{
          width: ["top", "bottom"].includes(expandIconPosition ?? "center")
            ? "100%"
            : "fit-content",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();

          onClick(id);
        }}
      >
        {!!ExpandIcon ? (
          <ExpandIcon {...props} />
        ) : (
          <DefaultExpandIcon {...props} />
        )}
      </div>
    </Div>
  );
};

const CollapsePanel = (props: CollapsePanelExtendedProps) => {
  const theme = useTheme();
  const {
    children,
    ghost,
    styles,
    align = "start",
    isActive,
    hasGap,
    expandIconPosition = "end",
    expandIconPositionTablet,
    noPadding,
  } = props;

  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const iconPosition =
    isTablet && expandIconPositionTablet
      ? expandIconPositionTablet
      : expandIconPosition;

  return (
    <CollapsePanelContainer
      $isOpened={isActive}
      $noPadding={noPadding}
      $ghost={ghost}
      $align={align}
      $hasGap={hasGap}
      style={{ ...styles?.container }}
    >
      {!!children && ["top"].includes(iconPosition) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ExpandButton {...props} expandIconPosition={iconPosition} />
        </div>
      )}
      <CollapseHeader {...props} expandIconPosition={iconPosition} />

      {!!children && (
        <ReactCollapse isOpened={isActive}>
          <div style={{ ...styles?.content }}>{children}</div>
        </ReactCollapse>
      )}

      {!!children && ["bottom"].includes(iconPosition) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ExpandButton {...props} expandIconPosition={iconPosition} />
        </div>
      )}
    </CollapsePanelContainer>
  );
};

export default CollapsePanel;

