import { createPortal } from "react-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import { TabletSaveContainer } from "./styles";
export interface ProfileFormFooterProps {
  handleFinish: () => void;
  handleReset?: () => void;
  isSmall?: boolean;
}

const ProfileFormFooterContent = ({
  handleFinish,
  handleReset,
  isSmall,
}: ProfileFormFooterProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <Div
      flex="row"
      gap={24}
      align="center"
      justify="flex-end"
      tablet={{
        flex: "column-reverse",
        align: "center",
        justify: "center",
        gap: 8,
        style: { width: "100%" },
      }}
      fitContent={isSmall}
    >
      {!!handleReset && (
        <Button
          onClick={handleReset}
          size={"small"}
          defaultStyle={theme.colors.white_1}
          tablet={{ style: { width: "100%" } }}
        >
          {t("button|cancel")}
        </Button>
      )}
      <Button
        onClick={handleFinish}
        size={"small"}
        tablet={{ style: { width: "100%" } }}
      >
        {t("button|save")}
      </Button>
    </Div>
  );
};
const ProfileFormFooter = (props: ProfileFormFooterProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
  return isTablet ? (
    createPortal(
      <TabletSaveContainer>
        <ProfileFormFooterContent {...props} />
      </TabletSaveContainer>,
      document.getElementById("root")!
    )
  ) : (
    <ProfileFormFooterContent {...props} />
  );
};
export default ProfileFormFooter;

