import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import theme from "@hellodarwin/core/lib/theme";
import GreenCheck from "@hellodarwin/icons/dist/icons/GreenCheck";
import classNames from "classnames";
import { getCategoryIcon } from "../../../features/helpers";
import { CategoryContainer } from "./styles";

type CategoryProps = {
  category: string;
  selected: boolean;
  onClick: (tag: string) => void;
};

const Category = ({ category, selected, onClick }: CategoryProps) => {
  const { t } = useTranslation();

  const classes = classNames({
    [`selected`]: selected,
  });

  return (
    <CategoryContainer
      className={classes}
      key={category}
      flex={"column"}
      gap={8}
      align={"center"}
      onClick={() => onClick(category)}
      borderRadius={0}
      $selected={selected}
    >
      <Div className={"category-container-saved-label"} fitContent>
        <GreenCheck
          size={24}
          color={theme.colors.white_1}
          bgColor={theme.colors.purple_2}
        />
      </Div>
      <Div
        flex={"row"}
        justify={"center"}
        align={"center"}
        className="category-icon-container"
      >
        {getCategoryIcon(category)}
      </Div>
      <Div flex={"row"} justify={"center"} align={"center"}>
        <Typography elementTheme="body2" textAlign="center">
          {t(`tags|${category}`)}
        </Typography>
      </Div>
    </CategoryContainer>
  );
};

export default Category;

