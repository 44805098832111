import Container from "@hellodarwin/core/lib/components/common/container";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";

import Grid from "@hellodarwin/core/lib/components/common/hd-grid";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import OnboardingFooter from "@hellodarwin/core/lib/components/onboarding/footer";
import OnboardingHeader from "@hellodarwin/core/lib/components/onboarding/header";
import {
  OnBoardingSteps,
  useOnBoarding,
} from "@hellodarwin/core/lib/context/onboarding-provider";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/app-hooks";
import Category from "../../../components/expertise/category";
import {
  selectTags,
  toggleCategoryTag,
} from "../../../features/api/slices/profile-slice";

const PartnerCategoryPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { categories, selectedCategories } = useAppSelector(selectTags);
  const { setCurrentStep } = useOnBoarding();

  const handleToggle = (tag: string) => {
    dispatch(toggleCategoryTag(tag));
  };

  const categoryCards =
    categories &&
    React.Children.toArray(
      categories.map((category) => (
        <Category
          key={category.label}
          category={category.label}
          selected={category.isSelected}
          onClick={() => handleToggle(category.label)}
        />
      ))
    );

  return (
    <PageLayout
      app="provider"
      title={t("provider-profile|expertise.category.title")}
      subtitle={t("expertise|category.title")}
      breadcrumbs={[]}
    >
      <OnboardingHeader />

      <Container>
        <Grid
          center
          xl={6}
          lg={4}
          md={3}
          sm={2}
          xxl={6}
          gutter={24}
          style={{ marginBottom: "1rem" }}
          className={"partner-category-card-grid"}
        >
          {categoryCards}
        </Grid>
      </Container>
      <OnboardingFooter
        handleNext={() => setCurrentStep(OnBoardingSteps.Services)}
        handlePrev={() => setCurrentStep(OnBoardingSteps.Contact)}
        nextDisabled={selectedCategories.length <= 0}
      />
    </PageLayout>
  );
};

export default PartnerCategoryPage;

