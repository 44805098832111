import { useCallback, useRef } from "react";

const useDebounceFunction = <T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
  isAfter?: boolean
): T => {
  const isDebouncing = useRef(false);

  return useCallback(
    (...args: Parameters<T>) => {
      if (!isDebouncing.current) {
        isDebouncing.current = true;
        !isAfter && callback(...args);
        setTimeout(() => {
          isDebouncing.current = false;
          isAfter && callback(...args);
        }, delay);
      }
    },
    [callback, delay]
  ) as T;
};

export default useDebounceFunction;

