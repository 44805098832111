import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const Network = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.474 21.2691C11.9397 19.7493 13.9274 18.8956 16 18.8956C18.0726 18.8956 20.0603 19.7493 21.526 21.2691M5.737 16.3564C11.4044 10.4773 20.5941 10.4773 26.263 16.3564M1 11.4438C9.28454 2.85207 22.7155 2.85207 31 11.4438M16.789 26.1817L16 27L15.211 26.1817C15.4203 25.9649 15.7041 25.8431 16 25.8431C16.2959 25.8431 16.5797 25.9649 16.789 26.1817Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Network;

