import Upload from "@hellodarwin/icons/dist/icons/Upload";
import Tooltip from "antd/es/tooltip";
import { useState } from "react";
import theme from "../../../theme";
import { AssetUploadFormProps } from "./asset-upload-form";
import AssetUploadModal from "./asset-upload-modal";
import { AssetUploadButton } from "./styles";

export interface AssetUploadProps extends AssetUploadFormProps {
  ghost?: boolean;
  size?: "small" | "medium" | "large";
}

const AssetUpload = ({ size, ghost, ...props }: AssetUploadProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const buttonSize = size === "large" ? 32 : size === "small" ? 16 : 24;
  const handleModal = () => setModalOpen(!modalOpen);
  return (
    <>
      <Tooltip title="Upload a new file to your company">
        <AssetUploadButton
          onClick={handleModal}
          size="circle"
          $ghost={ghost}
          transparent={ghost}
          defaultStyle={theme.colors.grey_1}
          trailingIcon={<Upload size={buttonSize} />}
        />
      </Tooltip>
      <AssetUploadModal
        open={modalOpen}
        handleCancel={handleModal}
        {...props}
      />
    </>
  );
};

export default AssetUpload;

