import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MappedTranslations } from "../../entities/translations-entities";
import {
  addParamToTranslation,
  flattenTranslations,
  FlatTranslation,
} from "./translations-utils";

export type GetTranslationFunc = (
  name: string,
  params?: { [key: string]: string | number }
) => string;

export interface TranslationsContextType {
  t: GetTranslationFunc;
  getFullLanguage: (lang: string) => string;
  prepareTranslations: (
    newTranslations: MappedTranslations[] | FlatTranslation
  ) => void;
}

export const TranslationsContext = React.createContext<TranslationsContextType>(
  {
    t: () => "",
    getFullLanguage: () => "",
    prepareTranslations: () => {},
  }
);

interface TranslationsProviderProps extends PropsWithChildren {
  initialTranslations?: FlatTranslation;
}

export const TranslationsProvider = ({
  children,
  initialTranslations = {},
}: TranslationsProviderProps) => {
  const [translations, setTranslations] =
    useState<FlatTranslation>(initialTranslations);

  const prepareTranslations = (
    newTranslations: MappedTranslations[] | FlatTranslation
  ) => {
    if (Object.keys(newTranslations).length) {
      const flatTranslations = Array.isArray(newTranslations)
        ? flattenTranslations(newTranslations)
        : newTranslations;
      setTranslations((prevTranslations) => ({
        ...prevTranslations,
        ...flatTranslations,
      }));
    }
  };

  useEffect(() => {
    prepareTranslations(initialTranslations);
  }, [initialTranslations]);

  const getTranslation: GetTranslationFunc = useMemo(
    () =>
      (name, params = {}) => {
        const translation = translations[name] || name;
        return addParamToTranslation(translation, params);
      },
    [translations]
  );

  const getFullLanguage = (language: string): string => {
    const languages: { [index: string]: string } = {
      fr: getTranslation("language|fr"),
      en: getTranslation("language|en"),
      "en:fr": getTranslation("language|any"),
    };

    return languages[language] || "";
  };

  return (
    <TranslationsContext.Provider
      value={{
        t: getTranslation,
        getFullLanguage,
        prepareTranslations,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = (): TranslationsContextType =>
  useContext(TranslationsContext);

export default TranslationsProvider;

