import { PaginationProps } from "antd/es/pagination/Pagination";
import { useTranslations } from "../../../features/providers/translations-provider";
import { PaginationStyled } from "./styles";

export interface PaginationConfig {
  total: number;
  currentPage?: number;
  onPageChange?: (page: number, pageSize: number) => void;
  pageSize?: number;
  align?: PaginationProps["align"];
}

const Pagination = ({
  total,
  align = "center",
  pageSize = 10,
  onPageChange,
  currentPage,
}: PaginationConfig) => {
  const { t } = useTranslations();

  return (
    <PaginationStyled
      total={total}
      pageSize={pageSize}
      onChange={onPageChange}
      current={currentPage}
      align={align}
    />
  );
};

export default Pagination;

