import React from "react";
import AssetCard from "../asset-card";
import { AssetsCardsContent } from "./styles";
import { FilesListContentProps } from "./types";

const FilesListCards: React.FC<FilesListContentProps> = ({
  items,
  activeItem,
}) => {
  return (
    <AssetsCardsContent>
      {React.Children.toArray(
        items.map((meta) => (
          <AssetCard meta={meta} isActive={meta.id === activeItem?.id} />
        ))
      )}
    </AssetsCardsContent>
  );
};
export default FilesListCards;

