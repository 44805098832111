import GridViewFill from "@hellodarwin/icons/dist/icons/GridViewFill";
import LineViewFill from "@hellodarwin/icons/dist/icons/LineViewFill";
import { RowSelectorButton, RowSelectorContainer } from "./styles";

type RowSelectorProps = {
  isRow: boolean;
  setIsRow: (isRow: boolean) => void;
};

const RowSelector = ({ isRow, setIsRow }: RowSelectorProps) => {
  const handleRow = () => setIsRow(true);
  const handleGrid = () => setIsRow(false);

  const iconSize = 20;

  return (
    <RowSelectorContainer>
      <RowSelectorButton $isActive={!isRow} onClick={handleGrid}>
        <GridViewFill width={iconSize} height={iconSize} />
      </RowSelectorButton>
      <RowSelectorButton $isActive={isRow} onClick={handleRow}>
        <LineViewFill width={iconSize} height={iconSize} />
      </RowSelectorButton>
    </RowSelectorContainer>
  );
};

export default RowSelector;
