import Dots from "@hellodarwin/icons/dist/icons/Dots";
import { ItemType } from "antd/es/menu/interface";
import { CSSProperties } from "react";
import { useTheme } from "styled-components";
import Button from "../button";
import Typography from "../typography";
import { DropdownContainer } from "./styles";

export type DropdownPlacement =
  | "bottom"
  | "top"
  | "bottomRight"
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "bottomLeft";

export type DropdownTriggerType = "contextMenu" | "click" | "hover";
export interface DropdownCtaProps {
  size?: number;
  color?: string;
  text?: string;
}

export interface DropdownProps {
  items: ItemType[];
  dropdownRender?: (originNode: React.ReactNode) => React.ReactNode;
  placement?: DropdownPlacement;
  menuStyle?: CSSProperties;
  cta?: DropdownCtaProps;
  ctaRender?: (cta: DropdownCtaProps) => React.ReactNode;
  overlayClassName?: string;
  overlayStyle?: CSSProperties;
  trigger?: DropdownTriggerType[];
}

const Dropdown = ({
  items,
  dropdownRender,
  placement,
  cta,
  ctaRender,
  menuStyle,
  overlayClassName,
  ...props
}: DropdownProps) => {
  const theme = useTheme();

  const ctaDefaultRender = (cta: DropdownCtaProps) => {
    const buttonSize = cta?.size ?? 16;
    const buttonColor = cta?.color ?? theme.colors.grey_2;
    const buttonText = cta?.text;

    return (
      <Button transparent size={"square"}>
        {!!buttonText && <Typography>{buttonText}</Typography>}
        <Dots color={buttonColor} size={buttonSize} />
      </Button>
    );
  };

  const ButtonRender = !!ctaRender ? ctaRender : ctaDefaultRender;

  return (
    <DropdownContainer
      menu={{ items, style: menuStyle }}
      dropdownRender={dropdownRender}
      placement={placement}
      overlayClassName={overlayClassName}
      {...props}
    >
      <div style={{ width: "fit-content" }}>
        <ButtonRender {...cta} />
      </div>
    </DropdownContainer>
  );
};

export default Dropdown;

