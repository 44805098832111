import { Suspense, lazy, useMemo } from "react";
import { HdLogoProps, LogoProps, theme } from "../../features/entities/general";

const PrimaryLogo = lazy(() => import("./PrimaryLogo"));
const SecondaryLogo = lazy(() => import("./SecondaryLogo"));
const TertiaryLogo = lazy(() => import("./TertiaryLogo"));

const LogoComponent = ({ type, ...logoProps }: LogoProps) => {
  switch (type) {
    case "secondary":
      return <SecondaryLogo {...logoProps} />;
    case "tertiary":
      return <TertiaryLogo {...logoProps} />;
    case "primary":
    default:
      return <PrimaryLogo {...logoProps} />;
  }
};

const HdLogo = (props: HdLogoProps) => {
  const { backgroundType, color } = props;
  const primaryColor = useMemo(
    () => (backgroundType === "dark" ? theme.white_1 : theme.grey_1),
    [backgroundType]
  );
  const secondaryColor = useMemo(
    () =>
      color === "b/w"
        ? backgroundType === "dark"
          ? theme.white_1
          : theme.grey_1
        : backgroundType === "dark"
          ? theme.purple_3
          : theme.purple_2,
    [color, backgroundType]
  );

  return (
    <Suspense fallback={null}>
      <LogoComponent
        {...props}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </Suspense>
  );
};

export default HdLogo;

