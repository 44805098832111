import Dots from "@hellodarwin/icons/dist/icons/Dots";
import Button from "../../../common/button";
import Dropdown, { DropdownProps } from "../../../common/dropdown";
import { AssetActionsContainer } from "../styles";

interface AssetRowActionsProps {
  items: DropdownProps["items"];
}

const AssetRowActions: React.FC<AssetRowActionsProps> = ({ items }) => {
  return (
    <AssetActionsContainer
      className="asset-actions"
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown
        items={items}
        trigger={["click"]}
        menuStyle={{ padding: 0 }}
        ctaRender={() => (
          <Button transparent size="square">
            <Dots />
          </Button>
        )}
      />
    </AssetActionsContainer>
  );
};

export default AssetRowActions;

