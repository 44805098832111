import { ModalProps } from ".";
import styled, { css } from "../../../plugins/styled";
import Avatar from "../avatar";
import Button from "../button";

const smallModal = css`
  width: 540px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw;
    max-width: 100vw;
  }
`;

const mediumModal = css`
  width: 760px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw;
    max-width: 100vw;
  }
`;

const largeModal = css`
  width: 1080px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw;
    max-width: 100vw;
  }
`;
const extraLargeModal = css`
  width: 1300px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 100vw;
    max-width: 100vw;
  }
`;
const fullModal = css`
  width: calc(100vw - 128px);
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}px) {
    width: 100vw;
    max-width: 100vw;
  }
`;

export const ModalBody = styled.div``;

export const ModalHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white_1};
  width: 100%;
  padding: 16px 96px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  box-sizing: border-box;
`;

export const ModalFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.white_1};
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
  width: 100%;
  padding: 16px 96px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  box-sizing: border-box;
`;

export const ModalContent = styled.div<{
  $noPadding?: boolean;
  $withHeader?: boolean;
  $withFooter?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.white_1};
  width: 100%;
  padding: ${({ $noPadding }) => ($noPadding ? 0 : "54px 96px")};
  border-radius: ${({ $withHeader }) => ($withHeader ? "0px 0px" : "4px 4px")}
    ${({ $withFooter }) => ($withFooter ? "0px 0px" : "4px 4px")};

  min-height: 200px;
  transition: height 100ms linear;
  box-sizing: border-box;
`;

export const ModalContainer = styled.div<{ $size: ModalProps["size"] }>`
  position: relative;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 10px;
  height: fit-content;
  margin: auto;
  .hd-modal-with-icon {
    &.hd-modal-horizontal {
      padding: 0px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      padding: 20px;
      &.hd-modal-horizontal {
        padding: 0px;
      }
    }
  }

  ${ModalBody} {
    width: 100%;

    display: flex;
    flex-direction: column;
    overflow: visible;
    box-sizing: border-box;
  }

  ${(props) =>
    props.$size === "small"
      ? smallModal
      : props.$size === "medium"
        ? mediumModal
        : props.$size === "large"
          ? largeModal
          : props.$size === "extra-large"
            ? extraLargeModal
            : props.$size === "full"
              ? fullModal
              : undefined}
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  padding: 32px 0;
  box-sizing: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey_1}bb;
  z-index: 1000;

  animation: fadein 0.3s;
  ${ModalContainer} {
    animation: grow 0.3s;
  }

  &.fade-out {
    animation: fadeout 0.3s;
    ${ModalContainer} {
      animation: shrink 0.3s;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes grow {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes shrink {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0);
    }
  }
`;

export const ModalLogo = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
`;

export const ModalCloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  min-height: 0;
  min-width: 0;
`;

export const ModalAvatar = styled(Avatar)`
  position: absolute;
  top: 0;
  left: 0;
`;

