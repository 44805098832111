import { IconProps } from "../features/entities/general";
import useIconProps from "../utils/use-icon-props";
import useStrokeWidth from "../utils/use-stroke-width";

const View = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67476 16.0461C1.57554 15.7479 1.57554 15.4256 1.67476 15.1274C3.66892 9.12762 9.32935 4.79999 16.0005 4.79999C22.6688 4.79999 28.3264 9.12331 30.3249 15.1202C30.4255 15.4178 30.4255 15.7399 30.3249 16.0389C28.3321 22.0386 22.6717 26.3663 16.0005 26.3663C9.33223 26.3663 3.67323 22.043 1.67476 16.0461Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7515 15.5831C21.7515 17.1084 21.1456 18.5712 20.0671 19.6497C18.9886 20.7282 17.5258 21.3341 16.0005 21.3341C14.4753 21.3341 13.0125 20.7282 11.9339 19.6497C10.8554 18.5712 10.2495 17.1084 10.2495 15.5831C10.2495 14.0579 10.8554 12.5951 11.9339 11.5166C13.0125 10.438 14.4753 9.83212 16.0005 9.83212C17.5258 9.83212 18.9886 10.438 20.0671 11.5166C21.1456 12.5951 21.7515 14.0579 21.7515 15.5831Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default View;

