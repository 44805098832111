import { IconNode } from "@hellodarwin/icons/dist/features/entities/general";
import Email from "@hellodarwin/icons/dist/icons/Email";
import Language from "@hellodarwin/icons/dist/icons/Language";
import Location from "@hellodarwin/icons/dist/icons/Location";
import { PropsWithChildren, ReactNode } from "react";
import { GMAPS_API_KEY } from "../../../../api";
import { Provider } from "../../../../features/entities";
import { getLongAddress } from "../../../../features/helpers/get-address";
import prefixHTTPS from "../../../../features/helpers/prefix-https";
import useLocale from "../../../../features/providers/locale-provider";
import { useTranslations } from "../../../../features/providers/translations-provider";
import { Status, Wrapper } from "../../../../features/wrappers/gmaps-wrapper";
import { useTheme } from "../../../../plugins/styled";
import Button from "../../../common/button";
import Div from "../../../common/div";
import Typography from "../../../common/typography";
import Loading from "../../../loading";
import ProfileSection from "../profile-section";
import ContactMap from "./contact-map";
import ProfileContactSocials from "./contact-socials";
import { InfoLineContainer } from "./styles";

type ProfileContactProps = {
  provider: Provider;
  isEditable?: boolean;
  isProject?: boolean;
  isWebsite?: boolean;
  handleShowContact?: () => void;
  handleRedirectToSettings?: () => void;
  providerPrimaryActions?: ReactNode;
  id: string;
};

const ProfileContact = ({
  provider,
  isEditable,
  isProject,
  isWebsite,
  providerPrimaryActions,
  handleShowContact,
  handleRedirectToSettings,
  id,
}: ProfileContactProps) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const { selectedLocale } = useLocale();
  const {
    city,
    province,
    postal_code,
    country,
    address,
    email,
    website,
    name,
  } = provider;

  const hasAddress =
    !!address || !!city || !!province || !!postal_code || !!country;

  const completeAddress = getLongAddress(address ?? "", {
    address: address ?? "",
    city: city ?? "",
    province: province ?? "",
    postal_code: postal_code ?? "",
    country: country ?? "",
  });

  const InfoLine = ({
    title,
    Icon,
    isLink,
    children,
  }: {
    title: string;
    Icon: IconNode;
    isLink?: boolean;
  } & PropsWithChildren) => {
    return (
      <InfoLineContainer>
        <Icon size={18} style={{ marginTop: 3, flexShrink: 0 }} />

        <Typography elementTheme="body2">
          {isLink ? (
            <a
              href={prefixHTTPS(title.toLowerCase(), "profile_contact")}
              target="_blank"
              rel="nofollow"
            >
              {title}
            </a>
          ) : (
            title
          )}
        </Typography>
        {children}
      </InfoLineContainer>
    );
  };

  const render = (status: Status) => {
    switch (status) {
      case Status.FAILURE:
        return <></>;
      case Status.LOADING:
        return <Loading />;

      default:
        return (
          <>
            {" "}
            {!!completeAddress && (
              <ContactMap address={completeAddress} name={provider.name} />
            )}
          </>
        );
    }
  };

  return (
    <ProfileSection
      title={t("profile_contact|contactTeam", {
        company: provider?.name,
      })}
      id={id}
      isEditable={isEditable}
      handleEdit={handleRedirectToSettings}
    >
      <Div
        flex="row"
        gap={71}
        xl={{ flex: "column-reverse", style: { gap: 0 } }}
        style={{ minHeight: 400 }}
      >
        <Div
          flex="column"
          gap={32}
          style={{ flex: 2 }}
          xl={{ style: { gap: 0 } }}
        >
          <div>
            {website && <InfoLine title={website} isLink Icon={Language} />}

            {!isProject && (
              <>{!!email && <InfoLine title={email} Icon={Email} />}</>
            )}
            {hasAddress && <InfoLine title={completeAddress} Icon={Location} />}

            <ProfileContactSocials {...provider} />
          </div>
          {!!isWebsite ? (
            <Button
              onClick={handleShowContact}
              textWrap
              size="small"
              fitContent
              defaultStyle={theme.colors.yellow_1}
            >
              {t("profile_banners|bannerServicesTitle", {
                company: name,
              })}
            </Button>
          ) : !!isProject ? (
            <>{providerPrimaryActions}</>
          ) : (
            !!isEditable && (
              <Button
                onClick={handleShowContact}
                textWrap
                size="small"
                fitContent
                defaultStyle={theme.colors.yellow_1}
              >
                {t("profile_contact|contactTeam", {
                  company: provider?.name,
                })}
              </Button>
            )
          )}
        </Div>
        <Wrapper
          apiKey={GMAPS_API_KEY}
          language={`${selectedLocale || "en"}-ca`}
          region="CA"
          id="GOOGLE_MAPS"
          render={render}
          libraries={["maps", "places", "marker"]}
          library="maps"
        />
      </Div>
    </ProfileSection>
  );
};
export default ProfileContact;

