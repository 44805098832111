import classNames from "classnames";
import { PropsWithChildren, useMemo } from "react";
import useMediaQuery from "../../../../features/helpers/use-media-query";
import { useAppData } from "../../../../features/providers/app-data-provider";
import useLocale from "../../../../features/providers/locale-provider";
import { usePlatform } from "../../../../features/providers/platform-provider";
import { getI18n } from "../../../../plugins/i18n/utils";
import theme from "../../../../theme";
import LoadingScreen from "../../../loading/screen";
import { AppLayoutContainer, GeneralLayout, MainLayout } from "./styles";

export interface AppLayoutProps extends PropsWithChildren {
  isLoading?: boolean;
}

const AppLayout = ({ children, isLoading }: AppLayoutProps) => {
  const { app } = usePlatform();
  const { nav, bottomNav } = useAppData();
  const containerClasses = classNames("hd-content-container");
  const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });

  const i18n = getI18n();

  const { localeProviderLoading, selectedLocale } = useLocale();

  const isAppLoading = useMemo(
    () =>
      isLoading || localeProviderLoading || i18n.language !== selectedLocale,
    [isLoading, localeProviderLoading, i18n.language, selectedLocale]
  );

  if (isAppLoading) return <LoadingScreen />;

  return (
    <AppLayoutContainer className={app}>
      <GeneralLayout hasSider={!isTablet}>
        {nav}
        <MainLayout
          className={containerClasses}
          style={{ minHeight: "100vh", height: "auto" }}
        >
          {children}
        </MainLayout>
        {bottomNav}
      </GeneralLayout>
    </AppLayoutContainer>
  );
};

export default AppLayout;

