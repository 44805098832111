import Warning from "@hellodarwin/icons/dist/icons/Warning";
import { CSSProperties } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider, useTheme } from "styled-components";
import { useTranslations } from "../../../features/providers/translations-provider";
import theme from "../../../theme";
import Button from "../button";
import Div from "../div";
import Typography from "../typography";
import { ModalContainer, ModalContent, ModalWrapper } from "./styles";
import useModalOpenFunctions from "./use-modal-open-functions";

interface ModalConfirmProps {
  onOk: () => Promise<void>;
  onCancel?: () => Promise<void>;
  title: string;
  content: string;
  okText?: string;
  cancelText?: string;
  cancelButtonProps?: { style: CSSProperties };
  okButtonProps?: { style: CSSProperties };
  width?: number;
}

interface ModalConfirmComponentProps extends ModalConfirmProps {
  onClose: () => void;
}
export const ModalConfirmComponent: React.FC<ModalConfirmComponentProps> = (
  props
) => {
  const { t } = useTranslations();
  const theme = useTheme();
  const {
    okText = t("button|confirm"),
    cancelText = t("button|cancel"),
    cancelButtonProps,
    okButtonProps,
    title,
    content,
    onOk,
    onCancel,
    onClose,
    width,
  } = props;

  const handleCancel = () => {
    !!onCancel && onCancel();
    onClose();
  };
  const handleConfirm = () => {
    onOk();
    onClose();
  };
  const {
    onCancel: onFinish,
    fadeOut,
    isOpen,
  } = useModalOpenFunctions({
    handleCancel,
    open: true,
  });

  if (!isOpen && !fadeOut) return <></>;

  return (
    <ModalWrapper onClick={onCancel}>
      <ModalContainer $size={"small"}>
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          style={{ width, minHeight: "auto" }}
        >
          <Div flex="column" gap={24}>
            <Div flex="column" gap={8} align="center">
              <Warning size={48} />
              <Typography
                elementTheme="subtitle1"
                color={theme.colors.primary}
                textAlign="center"
              >
                {title}
              </Typography>
              <Typography
                elementTheme="body1"
                color={theme.colors.primary}
                textAlign="center"
              >
                {content}
              </Typography>
            </Div>
            <Div flex="row" align="center" justify="center" gap={8}>
              <Button
                size="small"
                defaultStyle={theme.colors.white_1}
                style={cancelButtonProps?.style}
                onClick={onFinish}
              >
                {cancelText}
              </Button>
              <Button
                style={okButtonProps?.style}
                size="small"
                onClick={handleConfirm}
              >
                {okText}
              </Button>
            </Div>
          </Div>
        </ModalContent>
      </ModalContainer>
    </ModalWrapper>
  );
};
export const ModalConfirm = (props: ModalConfirmProps) => {
  let modalRoot = document.createElement("div");
  modalRoot.id = "modal-root";
  document.body.appendChild(modalRoot);

  let root = createRoot(modalRoot);

  const closeModal = async () => {
    root.unmount();
  };

  root.render(
    <ThemeProvider theme={theme}>
      <ModalConfirmComponent {...props} onClose={closeModal} />
    </ThemeProvider>
  );
};

export default ModalConfirm;

